import { COHORT_TYPES } from "./constants";


/**
 * 
 * Gets cohort type from a given cohort, based on its properties
 *
 */
export const getCohortType = (cohort) => {
    const constraintType = cohort.constraint_type;

    if (!constraintType) {
        return COHORT_TYPES.ROOT
    }

    if (constraintType.indexOf("INTERSECT") > -1 
        || constraintType.indexOf("SUBTRACT") > -1
        || constraintType.indexOf("UNION") > -1
    ) {
        return COHORT_TYPES.COMPOUND;
    }

    if ( constraintType.indexOf("PEDIGREE") > -1 ) {
        return COHORT_TYPES.PEDIGREES;
    }

    return COHORT_TYPES.SUBSET;
}
