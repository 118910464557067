//import PropTypes from "prop-types";
import { getPlainLangVariableConstraint } from "../../utils";


/**
 * 
 * Almost the same as Downcode Table, but doing the readonly 
 * version in a separate component keeps the other one from becoming too complex.
 * 
 */
export const DowncodeTableReadonly = ({construct}) => {

    const { default_value, value_definitions, } = construct;

    return (
        
        <section>
        
            <h4 className="h6">Value Definitions</h4>

            <table className="w-100">

                {/* Table headers */}
                <thead>
                    <tr>
                        <th>Original Value</th>
                        <th>New Value</th>
                    </tr>
                </thead>

                {/* Table data */}
                <tbody>

                    {value_definitions.reduce((constraints, value) => {
                            constraints.push(
                                ...(value.definition.constraints.map(c => ({...c, value: value.name})))
                            )
                            return constraints;
                        }, [])
                        .sort((a,b) => {
                            if (a.order > b.order) return 1;
                            if (a.order < b.order) return -1;
                            return 0;
                        })
                        .map((c, i) => (
                            <tr key={i}>
                                <td>{c.variable_name} {getPlainLangVariableConstraint(c.constraint_type, c.constraint_value)}</td>
                                <td>{c.value}</td>
                            </tr>
                        ))
                    }

                    <tr>

                        <td className="d-flex"> 
                            <div className="ml-auto">
                                Otherwise use default value:
                            </div> 
                        </td>

                        <td>{default_value}</td>

                    </tr>

                </tbody>

            </table>

        </section>

    )
}

/*DowncodeTableReadonly.propTypes = {
    construct: PropTypes.object
}*/
