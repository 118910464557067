import { useCallback } from "react"
//import PropTypes from "prop-types"

import { Button } from "../Buttons"
import { condClass } from "../../utils/conditionalClass"
import { useTableWrapperContext } from "../../contexts/TableWrapperContext"

export const PageLink = ({active, children, classes, goToPage, noBtnClass, variant}) => {

    const handleClick = useCallback((e) => {
        e.preventDefault(); 
        goToPage();
    }, [goToPage])

    const { id } = useTableWrapperContext();

    return (
        <li className={`paginate_button page-item${condClass(active, 'active')}${condClass(classes)}${condClass(!goToPage, 'disabled')}`}>
            <Button
                variant={variant}
                aria-controls={id}
                tabIndex="0"
                classes="page-link"
                noBtnClass={noBtnClass}
                onClick={handleClick}
            >
                {children}
            </Button>
        </li>
    )
}

/*PageLink.propTypes = {
    active: PropTypes.bool,
    children: PropTypes.node.isRequired,
    classes: PropTypes.string,
    goToPage: PropTypes.func,
    noBtnClass: PropTypes.bool,
    variant: PropTypes.oneOf(["primary", "secondary"])
}*/
