import { useState } from "react";
//import PropTypes from "prop-types";
import { capitalizeFirstLetter,/* cohortShape,*/ getCohortType, getPlainLangCompoundRelationship } from "../../utils";
import { COHORT_TYPES } from "../../utils/constants";
import { SvgIcon, Tooltip } from "../GeneralUI";



const getType = (cohort) => {
    if (!cohort.constraint_type) {
        return '';
    }
    if (getCohortType(cohort) === COHORT_TYPES.PEDIGREES) {
        return "pedigree"
    }
    if (cohort.constraint_type.indexOf("SUBTRACT") > -1) {
        return "subtract";
    }
    if (cohort.constraint_type.indexOf("INTERSECT") > -1) {
        return "intersect";
    }
    if (cohort.constraint_type.indexOf("UNION") > -1) {
        return "union";
    }
}

export const CohortTypeIcon = ({ cohort }) => {

    const type = getType(cohort);

    const iconName = type === "pedigree" ? type : `relation-${type}`;

    const tooltip = type === "pedigree" ? capitalizeFirstLetter(type) : getPlainLangCompoundRelationship(type);

    const [ showTooltip, setShowTooltip ] = useState(false);

    const toggleTooltipOn = () =>  {
        setShowTooltip(true);
    }
    
    const toggleTooltipOff = () => {
        setShowTooltip(false);
    } 

    return (
        <div 
            role="img"
            aria-label={type}
            onFocus={toggleTooltipOn}
            onBlur={toggleTooltipOff}
            onMouseEnter={toggleTooltipOn}
            onMouseLeave={toggleTooltipOff}
        >
            
            <SvgIcon name={iconName} size={28} />

            <Tooltip show={showTooltip}>
                {tooltip}
            </Tooltip>

        </div>
    )
}

/*CohortTypeIcon.propTypes = {
    cohort: PropTypes.shape(cohortShape), 
}*/
