import PropTypes from "prop-types";

const cohortActionsShape = {
    primary: PropTypes.arrayOf(PropTypes.object),
    secondary: PropTypes.arrayOf(PropTypes.object),
}

const cohortShape = {
    constraint_type: PropTypes.string,
    constraint_values: PropTypes.string,
    creation: PropTypes.string,
    description: PropTypes.string,
    include_harmonized: PropTypes.string,
    name: PropTypes.string,
    parent: PropTypes.string,
    cohortid: PropTypes.number,
    rowcount: PropTypes.number,
    variable_name: PropTypes.string,
}

const variableShape = {
    variable_name: PropTypes.string.isRequired,
    variable_lookup_id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        ]),
    variable_type: PropTypes.string,
    creation_date: PropTypes.string,
    instantiation_date: PropTypes.string,
    observations: PropTypes.number,
    cardinality: PropTypes.number,
    width: PropTypes.number,
    min_value: PropTypes.number,
    max_value: PropTypes.number,
    top_3_responses: PropTypes.arrayOf(
        PropTypes.arrayOf(
            PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number,
            ]))),
    contributors: PropTypes.arrayOf(PropTypes.object),
    question: PropTypes.string,
    explicit: PropTypes.string,
    implicit: PropTypes.string,
    answer_validation: PropTypes.string, // should be regex...?
    answer_validation_desc: PropTypes.string,
    data_type: PropTypes.string,
    possible_values: PropTypes.arrayOf(
        PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
            ])
        ),
    value_mapping: PropTypes.arrayOf(
        PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
            ])
        ),
}


export {
    cohortActionsShape,
    cohortShape,
    variableShape,
}
