//import PropTypes from 'prop-types';
//import { variableShape } from '../../utils';
// Replaces the vertical bars (|) in question text (used to mark line breaks)
// with newlines.

export const QuestionNewlines = ({ variable, field }) => {
    if (variable[field] === null || typeof variable[field] === 'undefined' || variable[field] === "-") return '';
    
    // use of ref to use innerText inspired by
    // https://medium.com/@jonas_wolfram/rendering-line-breaks-in-dynamic-content-in-react-27cbbce1d9d
    return (<p
        className={field !== "question" ? "mb-0 font-italic" : "mb-0"}
        ref={(element) => {
            if (element !== null) {
                element.innerText = variable[field].replace(/\|/g, "\n");
            }
        }} />)
}

/*QuestionNewlines.propTypes = {
    variable: PropTypes.shape(variableShape),
    field: PropTypes.string,
}*/
