//import PropTypes from "prop-types"

/**
 * Helper to add space to the UI
 */
export const Spacer = ({size}) => {
    return (
        <div className={`p-${size}`}></div>
    )
}

/*Spacer.propTypes = {
    size: PropTypes.oneOf([
        1,2,3,4,5,"1","2","3","4","5",
    ]).isRequired,
}*/
