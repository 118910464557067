import { useEffect, useRef } from "react"
//import PropTypes from "prop-types";

export const Modal = ({
    action, 
    children,
    id,
    handleClose,
    title
}) => {

    const buttonRef = useRef();

    useEffect(() => {
        document.body.classList.add('modal-open');
        buttonRef.current.focus();
        return (() => {
            document.body.classList.remove('modal-open');
        })
    }, [])

    return (

        <>
        
            <div className={`modal fade show`} id={id} tabIndex="-1" role="dialog" aria-labelledby={`${id}-label`}>
            
                <div className="modal-dialog" role="document">
                
                    {/* Content */}
                    <div className="modal-content">

                        {/* Header */}
                        <div className="modal-header">

                            {/* Close button */}
                            <button ref={buttonRef} onClick={handleClose} type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>

                            {/* Title */}
                            <h2>{title}</h2>

                        </div>

                        {/* Body */}
                        <div className="modal-body">
                            {children}
                        </div>

                        {/* Footer */}
                        <div className="modal-footer">
                            {action}
                        </div>

                    </div>
                </div>

            </div>
            
            <div className="modal-backdrop fade show"></div>
        </>
    )
}


/*Modal.propTypes = {
    action: PropTypes.node,
    children: PropTypes.node.isRequired,
    handleClose: PropTypes.func.isRequired,
    id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    title: PropTypes.string.isRequired,
}*/
