import { useEffect, useRef, useState } from "react";
//import PropTypes from "prop-types";

import { condClass } from "../../utils/conditionalClass";
import { IconButton } from "../Buttons"
import { useIsFirstRender } from '../../hooks'
import { Count } from "../Typography";
import { CohortNameLink, CohortTypeIcon, ConnectingArrow, DeleteCohortButton } from ".";
import { COHORT_TYPES } from "../../utils/constants";
import { /*cohortActionsShape, cohortShape, */getCohortType } from "../../utils";


export const Cohort = ({
    actions = {}, 
    childCohortRef, 
    cohort, 
    includeArrow, 
    indent, 
    noDelete, 
    noLogic,
    text
}) => {
    
    // Primary actions are orange and go in visible part of Cohort component;
    // Secondary actions are smaller, outlined, and go in the collapsed part of the component
    const { primary : primaryActions = [], secondary: secondaryActions = [] } = actions;

    const cohortRef = useRef();

    const isFirstRender = useIsFirstRender();
    const collapseRef = useRef();
    const [ collapsed, setCollapsed ] = useState(false);
    const [ preCollapsedHeight /*, setPreCollapsedHeight */ ] = useState(null);

    // Sets height of expandable part of the Cohort component for smoother animation
    /* const toggleCollapsed = () => {
        if (!collapsed) {
            const height = collapseRef.current.clientHeight;
            setPreCollapsedHeight(height)
        }
        else  {
            setPreCollapsedHeight(0)
        }
    } */

    // Reacts to new height of expandable part to actually collapse or show it
    useEffect(() => {
        if (preCollapsedHeight === null) return;
        
        if (preCollapsedHeight) {
            setCollapsed(true)
        } else {
            setCollapsed(false)
        }
    }, [preCollapsedHeight, isFirstRender])


    if (!cohort) {
        return (
            <div className={`cohort${indent ? ' cohort--indent' : ''} cohort--empty`}>
                <div className="cohort__main">
                
                    <div className="cohort__content">

                        <div className="cohort__visible h-100">
                            {text || "No data for cohort"}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className={`cohort${condClass(indent || cohort.parents, 'cohort--indent')}`}>

            {/* Parents in a logic tree  */}
            { cohort.parents &&

                <div className={`cohort__parents`}>
                    
                    {/* First parent */}
                    <Cohort 
                        noDelete
                        cohort={cohort.parents[0]} 
                        actions={actions}
                    />
                    
                    {/* Relationship icon */}
                    {getCohortType(cohort) !== COHORT_TYPES.PEDIGREES &&
                        <div className="cohort__relationship">

                            <div className="cohort__visible justify-content-center p-1">
                                <CohortTypeIcon cohort={cohort} />
                            </div>

                        </div>
                    }
                    
                    {/* Second parent */}
                    { cohort.parents[1] &&
                        <Cohort 
                            noDelete
                            cohort={cohort.parents[1]}
                            actions={actions}
                        />
                    }

                </div>

            }

            {/* The main output of a cohort */}
            <div className={`cohort__main${condClass(getCohortType(cohort) === COHORT_TYPES.PEDIGREES , 'cohort__main--pedigree')}`}>

                { 
                    getCohortType(cohort) === COHORT_TYPES.PEDIGREES &&

                    <div className="cohort__relationship ml-0">

                        <div className="cohort__visible justify-content-center p-1">              
                            <CohortTypeIcon cohort={cohort} />
                        </div>

                    </div>
                
                }
                    
                <div className="cohort__content" ref={cohortRef}>

                    <div className="cohort__visible">
                        
                        <CohortNameLink cohort={cohort} />

                        <Count>{cohort.rowcount}</Count>

                        
                        {/* Primary actions */}
                        <div className="ml-auto icon-btn-group">

                            {
                                primaryActions.map((action, i) => {
                                    let to = '';
                                    let onClick = null;
        
                                    if ((noLogic || cohort.parents || cohort.parent === null) && action.name === "logic" ) {
                                        return '';
                                    }

                                    if (action.to) {
                                        to = action.to.replace(':id', cohort.cohortid)
                                    } else if (action.onClick) {
                                        onClick = () => action.onClick(cohort, cohortRef)
                                    }
                                    
                                    return <IconButton 
                                                key={i} 
                                                {...action} 
                                                to={to} 
                                                onClick={onClick} 
                                                variant={action.variant ? action.variant : "outline"}
                                            />
                                })
                            }

                        </div>

                        {/*
                        <IconButton 
                            onClick={toggleCollapsed}
                            tooltip="Show More"
                            iconName="ellipsis-v"
                            variant="info"
                        />*/}

                    </div>

                    {/* Expandable */}
                    <div className={`position-relative clearfix cohort__description${condClass(collapsed, 'cohort__description--collapsed')}`} ref={collapseRef} style={preCollapsedHeight && !collapsed ? {height: preCollapsedHeight} : null}>
                         
                         {/* Secondary actions */}
                         <div className="ml-auto icon-btn-group float-right">

                            {!noDelete && !cohort.children && parseInt(cohort.cohortid) !== 1 &&
                                <DeleteCohortButton
                                    cohort={cohort}
                                />
                            }
                            
                            {
                                secondaryActions.map((action, i) => {
                                    let to = '';
                                    let onClick = null;
        
                                    if (action.to) {
                                        to = action.to.replace(':id', cohort.cohortid)
                                    } else if (action.onClick) {
                                        onClick = () => action.onClick(cohort, cohortRef)
                                    }
                                    
                                    return <IconButton 
                                                key={i} 
                                                {...action} 
                                                to={to} 
                                                onClick={onClick} 
                                                variant={action.variant ? action.variant : "outline"}
                                            />
                                })
                            }
                        </div>
                        
                        {/* Description */}
                        {cohort.description || 'No description available.'}
                        
                    </div>

                    {includeArrow && (!cohort.children || !cohort.children.length) &&
                        <ConnectingArrow 
                            childCohortRef={childCohortRef}
                        />
                    }

                </div>

            </div>

            {/* Children in a logic tree */}
            { cohort.children &&
                <div className={`cohorts${cohort.cohortid === 1 ? ' p-0' : ''}`}>
                    {cohort.children.map(child => (
                        <Cohort 
                            key={child.cohortid} 
                            cohort={child} 
                            indent={true} 
                            actions={actions}
                            includeArrow={includeArrow}
                            childCohortRef={childCohortRef}
                            noLogic={true}
                        />
                    ))}
                </div>
            }
        </div>
    )
}

/*Cohort.propTypes = {
    actions: PropTypes.shape(cohortActionsShape), 
    childCohortRef: PropTypes.oneOfType([
        PropTypes.func, 
        PropTypes.shape({ current: PropTypes.any })
    ]), 
    cohort: PropTypes.shape(cohortShape), 
    includeArrow: PropTypes.bool, 
    indent: PropTypes.bool, 
    noDelete: PropTypes.bool, 
    noLogic: PropTypes.bool,
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
}*/
