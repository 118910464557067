import { useState } from "react";
//import PropTypes from "prop-types";

import { condClass } from "../../utils/conditionalClass";
import { textFieldValidators } from "../../utils";

let count = 0;

export const TextInput = ({ classes, helpText, hideLabel, label, name, onChange, required, textarea, validate, inputregex, fieldType, value, ...rest}) => {

    const Input = textarea ? 'textarea' : 'input';
    
    helpText = helpText ? helpText :
            fieldType && textFieldValidators[fieldType].helptext ?
                textFieldValidators[fieldType].helptext
            : undefined;
    validate = validate ? validate :
            fieldType && textFieldValidators[fieldType].validate ?
                textFieldValidators[fieldType].validate
            : undefined;
    inputregex = inputregex ? inputregex :
            fieldType && textFieldValidators[fieldType].regex ?
                textFieldValidators[fieldType].regex
            : undefined;

    // Generates automatic unique id
    const [ id ] = useState(('textInput-' + count++));

    const [ error, setError ] = useState(false)

    // update parent component
    const handleChange = (e) => {
        if (inputregex) {
            e.target.value.match(inputregex) && onChange(e.target.value, e.target.name);
        } else {
            onChange(e.target.value, e.target.name);
        }

        // re-validate (if there WAS an error, check if it's gone)
        if (error && validate) {
            setError(validate(e.target.value))
        }
    }

    // validate on blur
    const handleBlur = () => {
        if (required && !value) {
            if (typeof required === "string") {
                setError(required)
            } else {
                setError(`${label} is required.`)
            }
        }
        else if (validate) {
            setError(validate(value))
        }
        else {
            setError(false)
        }
    }

    return (
        <div className="d-flex flex-column">

            {/* Label */}
            {!hideLabel && label && 
                <label htmlFor={id}>
                    {label}{required ? <>*<span className="sr-only">(Required)</span></> : ""}
                </label>
            }

            {/* Input */}
            <Input 
                id={id} 
                type="text" 
                aria-label={hideLabel ? label : false}
                name={name}
                className={`form-control${condClass(classes, classes)}${condClass(error, 'is-invalid')}`}
                onChange={handleChange}
                onBlur={handleBlur}
                value={value}
                aria-describedby={`${helpText ? id + "-help " : ""}${error ? id + "-error " : ""}` || null}
                {...rest} 
            />

            {/* Help text */}
            {helpText &&
                <small id={`${id}-help`} className="form-text show">{helpText}</small>
            }

            {/* Error message */}
            {!!error &&
                <small id={`${id}-error`} className="form-text invalid-feedback show">{error}</small>
            }

        </div>
    )
}

/*TextInput.propTypes = {
    classes: PropTypes.string,
    helpText: PropTypes.string,
    hideLabel: PropTypes.bool,
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    required: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.string,
    ]),
    textarea: PropTypes.bool,
    validate: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    fieldType: PropTypes.string,
}*/
