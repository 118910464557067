import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Alert, Icon } from '../../components/GeneralUI';

import { VariableSetsTable } from '../../components/VariableSets';
import { Button } from '../../components/Buttons';
import { useAppState/*, useCancelAsyncCall*/ } from '../../hooks';
import { InnerPage } from '../../layout/InnerPage';
import { getPathFromRouteName } from '../../utils';
import { NEW_VARIABLE_SET } from '../../utils/constants';
import api from '../../utils/api';

const pageProps = {
    title: "Work With Variable Sets",
    intro: "Variable Sets are lists of variables of interest to you or your study; they're one of the two elements of a Data Download (the other being a cohort). Here you can review and edit variable sets you may have already defined (just click on the set in the list below), or define a new one (click the orange button above).",
} 

const PageActions = () => (

    <div className="d-flex align-items-center">
        
        <Button variant="secondary" classes="position-static" to={getPathFromRouteName(NEW_VARIABLE_SET)}>
            Create New Variable Set <Icon name="plus-circle"></Icon>
        </Button>
    
    </div>

)

export const MyVariableSets = () => {

    //const cancelAsyncCall = useCancelAsyncCall();
    const { errorCheckText } = useAppState();

    const [ loading, setLoading ] = useState(true);
    const [ variableSets, setVariableSets ] = useState([]);

    // get all variable sets
    useEffect(() => {

        setLoading(true);

        api.getAllVariableSets()
            .then(res => {
                //if (cancelAsyncCall()) return null;
                
                setVariableSets(res.data.varsets)
            })
            .catch(err => {
                toast.error(errorCheckText(err, 'Unable to get Variable Sets.'));
            })
            .finally(() => setLoading(false))

    }, [/*cancelAsyncCall, */errorCheckText])


    // If there are no Variable Sets in this Project
    if (!variableSets || !variableSets.length) {

        return (
            <InnerPage {...pageProps} loading={loading} actions={(<PageActions/>)}>
                <Alert variant="info">No Variable Sets have been created in this project yet! <Link to={getPathFromRouteName(NEW_VARIABLE_SET)}>Create a new one here.</Link></Alert>
            </InnerPage>
        )
    
    }

    return (
        <InnerPage {...pageProps} loading={loading} actions={(<PageActions/>)}>

            <VariableSetsTable
                variableSets={variableSets}
            />

        </InnerPage>
    )
}


