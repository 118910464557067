import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { ProjectForm } from '../../components/Projects'
import { InnerPage } from '../../layout'
import { getPathFromRouteName } from '../../utils';
import { useAppState } from "../../hooks";
import api from '../../utils/api';
import { VIEW_PROJECT } from '../../utils/constants';

export const NewProject = () => {

    const navigate = useNavigate();
    const { setProject, errorCheckText } = useAppState();

    // Submit edit form for name and description
    const handleSubmit = (formValues) => {
        const { description, name } = formValues;

        if (name || description) {
            api.createProject(formValues)
                .then(res => {
                    const newprojectid = res.data.new_project_id
                    // Switch to the newly created project and redirect
                    // to view it.
                    api.setCurrentProject({ projectid: newprojectid })
                        .then(res => {
                            setProject(res.data)
                            navigate(getPathFromRouteName(VIEW_PROJECT,
                                    { id: newprojectid }))
                        })
                        .catch(err => {
                            toast.error(errorCheckText(err, `Unable to change current working project to new project id ${newprojectid}.`));
                        })
                })
                .catch(err => {
                    toast.error(errorCheckText(err, 'Unable to save Project.'));
                });
        }
        
    }


    return (
        <InnerPage
            title="Create a New Project"
        >

            <ProjectForm
                onSubmit={handleSubmit}
            />

        </InnerPage>
    )

}
