import { useCallback } from "react"
//import PropTypes from "prop-types"

import { COHORT_TYPES } from "../../utils/constants"
//import { cohortActionsShape, cohortShape } from '../../utils'
import { Cohort } from "./Cohort"


export const CohortTree = ({ cohortActions, cohorts }) => {


    const renderCohorts = useCallback((title, cohorts) => {
        if (cohorts && cohorts.length > 0) {
            return (
                <section className="mt-5">
                    <h3 className="h5">{title}</h3>
                    
                    { cohorts.map((cohort, i) => (
                        <div key={i} className={`${i ? "pt-2" : ''}`}>
                            <Cohort 
                                cohort={cohort} 
                                padding={false} 
                                actions={cohortActions} 
                            />
                        </div>
                    ))}
                </section>
            )
        }
        return '';
    }, [cohortActions])



    return (
        <div className="cohorts p-0">
            
       
            {renderCohorts("Subsets", cohorts[COHORT_TYPES.SUBSET])}
 
               
            {renderCohorts("Compound Sets", cohorts[COHORT_TYPES.COMPOUND])}

            
            {renderCohorts("Pedigrees", cohorts[COHORT_TYPES.PEDIGREES])}

        </div>
    )
}

/*CohortTree.propTypes = {
    actions: PropTypes.shape(cohortActionsShape),
    cohorts: PropTypes.shape({
        [COHORT_TYPES.SUBSET]: PropTypes.arrayOf(PropTypes.shape(cohortShape)),
        [COHORT_TYPES.COMPOUND]: PropTypes.arrayOf(PropTypes.shape(cohortShape)),
        [COHORT_TYPES.PEDIGREES]: PropTypes.arrayOf(PropTypes.shape(cohortShape)),
    })
}*/
