//import PropTypes from 'prop-types';

import { Button } from '../Buttons';
import { TextInput } from '../FormElements/TextInput';
import { Variable } from './Variable';
import { Alert, Icon, Spacer } from '../GeneralUI';
//import { variableShape } from '../../utils';


export const VariableCollectionForm = ({ 
    componentLabel,
    description,
    disableSave,
    name,
    note, 
    onReset, 
    onSubmit, 
    setDescription,
    setName,
    nameType,
    variableActions, 
    variablesLabel,
    variables 
}) => {

    const handleReset = (e) => {
        e.preventDefault();
        
        if (onReset) {
            onReset();
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        if (onSubmit) {
            onSubmit();
        }
    }


    return (
        <section className="section--blue">

            <form onReset={handleReset} onSubmit={handleSubmit}>

                {
                    // Main inputs: name and description
                    <div className="mb-4">
                        
                        { setName &&
                            <TextInput 
                                name="title"
                                value={name}
                                onChange={setName}
                                label={`Name your ${componentLabel}`}
                                fieldType={nameType || "variable_name"}
                                required="Name is required."
                            />
                        }

                        { setDescription &&
                            <TextInput 
                                name="description"
                                value={description}
                                onChange={value => setDescription(value)}
                                label="Description"
                                textarea
                            /> 
                        }

                    </div>
                }

                {/* Variables */}
                <div className="font-weight-600">
                    {variablesLabel ? variablesLabel : `Variables included in ${componentLabel}` }
                </div>

                {
                    variables.length > 0 ?
                        variables.map((variable, i) => (
                            <div key={i} className="my-2">
                                <Variable variable={variable} actions={variableActions} />
                            </div>
                        ))
                    :
                        <div className="font-italic my-2">
                            To get started, search for variables below and add them to your {componentLabel}.
                        </div>
                }

                {/* Alert, if needed */}
                { note && 
                    <div className="mt-4">
                        <Alert variant="warning">
                            {note}
                        </Alert>
                    </div>
                }


                {/* Buttons */}
                { (onSubmit || onReset) &&

                    <div className="d-flex mt-4 justify-content-end align-items-center">

                        { onReset &&
                            <Button
                                size="xs"
                                variant="primary"
                                classes="font-weight-600"
                                uppercase
                                type="reset"
                            >
                                Cancel
                            </Button>
                        }

                        <Spacer size={1} />
                        
                        { onSubmit &&
                            <Button 
                                disabled={disableSave}
                                size="md" 
                                variant="secondary"
                                classes="font-weight-600"
                                uppercase
                                type="submit"
                            >
                                Save {componentLabel} <Icon name="check-circle" />
                            </Button>
                        }

                    </div>
                }

            </form>

        </section>
    )
}

/*VariableCollectionForm.propTypes = {
    componentLabel: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    setName: PropTypes.func,
    nameType: PropTypes.string,
    description: PropTypes.string,
    disableSave: PropTypes.bool,
    setDescription: PropTypes.func,
    note: PropTypes.string,
    onReset: PropTypes.func,
    onSubmit: PropTypes.func,
    variableActions: PropTypes.arrayOf(
        PropTypes.oneOfType([PropTypes.object,  PropTypes.func])
    ),
    variablesLabel: PropTypes.string,
    variables: PropTypes.arrayOf(PropTypes.shape(variableShape)),
}*/
