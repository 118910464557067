import { ToastContainer, Slide } from 'react-toastify';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

import { Header } from "./layout";
import { ROUTES } from "./utils/constants";
import { ScrollToTop } from "./components/Utilities";
import { AppStateProvider } from './contexts';
import { NotFound } from "./pages/Errors";
import { LoginWrapper } from "./components/GeneralUI";


function App() {

    return (
        
        <Router>

            <AppStateProvider>

                {/* Scrolls to top of page when route changes */}
                <ScrollToTop />

                {/* DIVER title, navs */}
                <Header />
                
                {/* Displays error/success messages for user */}
                <ToastContainer 
                    position="top-center"
                    pauseOnHover
                    theme="colored"
                    autoClose={5000}
                    closeOnClick
                    transition={Slide}
                />

                <LoginWrapper>

                    {/* Sets up the routes and their pages */}
                    <Routes>
                        { 
                            ROUTES.map(route => (
                                <Route 
                                    exact
                                    key={route.path}
                                    path={route.path}
                                    Component={route.component}
                                />
                            ))
                        }

                        <Route 
                            Component={NotFound}
                        />

                    </Routes>

                </LoginWrapper>

            </AppStateProvider>

        </Router>

    );
}

export default App;
