import { useState } from "react";
//import PropTypes from "prop-types";

import { TableWrapper } from "../Tables";

const tableId = "cohort-records"

/**
 * Table for displaying cohort records
 */
export const CohortPreviewSummaries = ({ records, isPedigreeForm, noPedVar }) => {

    const [ visibleRecords, setVisibleRecords ] = useState([])

    return (
        <TableWrapper rows={records} setVisibleRows={setVisibleRecords} tableId={tableId}>

            <table className="w-100" id={tableId}>
                <thead>
                    {isPedigreeForm ?
                            <tr>
                                <th>Pedigrees Count</th>
                                <th>Total Individuals</th>
                                <th>{noPedVar ? "Pedigree Size" : "Affecteds Count"}</th>
                                <th>Collection</th>
                            </tr>
                                :
                            <tr>
                                <th>Individuals Count</th>
                                <th>Value</th>
                                <th>Collection</th>
                                <th>Consent</th>
                                <th>Study ID</th>
                                <th>NIMH DX</th>
                            </tr>
                            }
                </thead>

                {visibleRecords && visibleRecords.length > 0 ? 
                    <tbody>
                        {visibleRecords.map((record, i) => (
                            isPedigreeForm ?
                                    <tr key={i}>
                                        <td>{record.pedigree_count}</td>
                                        <td>{record.count}</td>
                                        <td>{record.affected_count}</td>
                                        <td>{record.collection}</td>
                                    </tr>
                                        :
                                    <tr key={i}>
                                        <td>{record.count}</td>
                                        <td>{record.value}</td>
                                        <td>{record.collection}</td>
                                        <td>{record.consent}</td>
                                        <td>{record.study_id}</td>
                                        <td>{record.dx_nimh}</td>
                                    </tr>
                        ))}
                    </tbody>
                :
                    <tbody>
                        <tr>
                        <td colSpan="7" className="text-center">
                            No results found.
                        </td>
                        </tr>
                    </tbody>
                }
            </table>

        </TableWrapper>
    )
}


/*CohortPreviewSummaries.propTypes = {
    records: PropTypes.arrayOf(PropTypes.object),
    isPedigreeForm: PropTypes.bool,
    noPedVar: PropTypes.bool,
}*/
