//import PropTypes from "prop-types";

/**
 * Displays the small, italic count, i.e. of members in a cohort, if it exists
 */
export const Count = ({children}) => {
    if (children === null || typeof children === 'undefined') return '';
    
    return (
        <small className="count pl-1"><i>({children})</i></small>
    )
}

/*Count.propTypes = {
    children: PropTypes.node,
}*/
