import { useEffect, useState } from "react";
//import PropTypes from "prop-types";

export const Tooltip = ({children, show}) => {

    const [ showTooltip, setShowTooltip ] = useState(false);
    const [ showTooltipTransition, setShowTooltipTransition ] = useState(false);

    useEffect(() => {
        if (show) {
            //display, then transition
            setShowTooltip(true);
            setTimeout(() => setShowTooltipTransition(true), 50)
        }
        else {
            // transition, then hide
            setShowTooltipTransition(false);
            setTimeout(() => setShowTooltip(false), 50)
        }
    }, [show])

    if (!showTooltip) {
        return <></>
    }

    return (
        // Tooltip 
        <div className={`tooltip fade ${showTooltipTransition && 'show'} bottom`} role="tooltip" data-placement="bottom">
            <div className="tooltip-arrow"></div>
            <div className="tooltip-inner">{children}</div>
        </div>
            
    )
}


/*Tooltip.propTypes = {
    children: PropTypes.node.isRequired,
    show: PropTypes.bool.isRequired,
}*/
