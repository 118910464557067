//import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// The "button" navigation in the top right
export const QuickLinks = ({ items, funcref }) => {
    return (

        
            <div className="btn-group btn-group--page-actions" role="group" aria-label="Page Actions">
                
                {items.map((item, i) => (
                    
                    item.hideIf ||

                        <Link
                            key={i}
                            to={item.to}
                            onClick={item.onclick}
                            target={item.target ? item.target : null}
                            className={item.isTextLink ? "d-flex align-items-center mr-3" : "btn btn-primary"}>
                            {item.text}
                        </Link>

                ))}

            </div>
        

    )
}

/*QuickLinks.propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape({
        to: PropTypes.string.isRequired,
        text: PropTypes.node.isRequired,
        isTextLink: PropTypes.bool,
    }))
}*/
