import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router"
import { toast } from "react-toastify"

import { Button, IconButton } from "../../components/Buttons"
import { Alert, Spacer, Icon } from "../../components/GeneralUI"
import { CohortTree } from "../../components/Cohorts"
import { VariableSetsTable } from "../../components/VariableSets"
import { VariablesTable } from "../../components/Variables"
import { DeleteProjectButton, ProjectForm } from "../../components/Projects"
import { useAppState/*, useCancelAsyncCall*/ } from "../../hooks"
import { InnerPage } from "../../layout"
import { getPathFromRouteName, parseCohortListToTree } from "../../utils"
import api from '../../utils/api'
import { HOME, LIST_PROJECTS } from "../../utils/constants"

export const ViewProject = () => {

    const { id } = useParams();
    //const cancelAsyncCall = useCancelAsyncCall();
    const navigate = useNavigate();
    const { setProject: setProjectGlobal, errorCheckText } = useAppState();
    
    const [ project, setProject ] = useState({});
    const [ editing, setEditing ] = useState(false);
    const [ loading, setLoading ] = useState(true);
    const [ cohorts, setCohorts ] = useState(null)
    const [ variableSets, setVariableSets ] = useState([]);
    const [ variables, setVariables ] = useState([]);
    
    const changeProject = () => {
        api.setCurrentProject({ projectid: id})
            .then(res => {
                //if (cancelAsyncCall()) return null;
                setProjectGlobal(res.data)
                navigate(getPathFromRouteName(HOME));
            })
            .catch(err => {
                toast.error(errorCheckText(err, `Unable to change current working project to id ${id}.`));    
            })  
    }

    // Action for top right corner
    const projectActions = [
        {
            name: 'swap',
            onClick: changeProject,
            tooltip: 'Work on Project',
            iconName: 'clipboard-check',
        }
    ]

    // Get the project and its children
    useEffect(() => {
        Promise.allSettled([
            api.getProject(id)
                .then(res => {
                    //if (cancelAsyncCall()) return null;
                    setProject(res.data.projects[0])
                }, err => {
                    toast.error(errorCheckText(err, `Unable to get project with id ${id}.`));    
                }),
            api.getAllCohorts(id)
                .then(res => {
                    //if (cancelAsyncCall()) return null;
                    let parsedCohorts;
                    try {
                        parsedCohorts = parseCohortListToTree(res.data.cohorts);
                    } catch(e) {
                        console.log(e)
                    }
                    setCohorts(parsedCohorts);
                }, err => {
                    toast.error(errorCheckText(err, `Unable to get Cohorts for project id ${id}.`));
                }),
            api.getAllVariableSets(id)
                .then(res => {
                    //if (cancelAsyncCall()) return null;
                    setVariableSets(res.data.varsets);
                }, err => {
                    toast.error(errorCheckText(err, `Unable to get Variable Sets for project id ${id}.`));
                }),
            api.getCustomVariables(id)
                .then(res => {
                    //if (cancelAsyncCall()) return null;
                    setVariables(res.data.custom_vars);
                }, err => {
                    toast.error(errorCheckText(err, `Unable to get custom Variables for project id ${id}.`));
                })
            ])
        .then(res => {
            setLoading(false);
        })
    }, [/*cancelAsyncCall,*/ navigate, id, errorCheckText])


    // Submit edit form for name and description
    const handleSubmit = (formValues) => {
        const { description, name } = formValues;

        if (name || description) {
            api.updateProject({
                    ...formValues,
                    id: id,
                })
                .then(res => {
                    
                    setEditing(false)
                    toast.success('Changes saved.')

                    // Get the updated project and update in state
                    api.getProject(id)
                        .then(res => {
                            setProject(res.data.projects[0]);
                        })
                        .catch(err => {
                            toast.error(errorCheckText(err, 'Unable to update changes on page. Please reload this window.'));
                        })
                        
                })
                .catch(err => {
                    toast.error(errorCheckText(err, 'Unable to save Project.'));
                });
        }
        
    }


    // If Project is not found
    if (!project || !project.name) {
        return (
            <InnerPage
                title="View Project"
            >

                <Alert variant="warning">No project was found with the id "{id}"</Alert>

            </InnerPage>
        )
    }


    return (
        <InnerPage
            loading={loading}
            title={project.name}
            intro={editing ? '' : project.description}
            editButton={<Button classes="btn--text-link" onClick={() => setEditing(true)}>Edit <Icon name="pencil-alt" /></Button>}
            actions={
                <div className="icon-btn-group">

                    <DeleteProjectButton
                        project={project}
                        returnRoute={LIST_PROJECTS}
                    />
                    
                    {projectActions.map((action, i) => (
                        <IconButton
                            key={i} 
                            {...action}
                            variant={action.variant ? action.variant : "outline"}
                        />
                    ))}
                </div>

            }
        >

            { editing &&
                <ProjectForm
                    initialName={project.name}
                    initialDescription={project.description}
                    onReset={() => { setEditing(false) }}
                    onSubmit={handleSubmit}
                />
            }
            

            <Spacer size={4} />
            <h4 className="h5">Cohorts In Project {project.name}</h4>
            {!cohorts || cohorts.length === 0 ? 
                <Alert variant="warning">
                    A problem has occurred and no cohorts could be loaded. Please try again later.
                </Alert>
            :
                <CohortTree 
                    cohorts={cohorts} 
                />
            }
            
            <Spacer size={4} />
            <h4 className="h5">Variable Sets In Project {project.name}</h4>
            { !variableSets || !variableSets.length ?
                <Alert variant="info">No Variable Sets have been created in this project yet!</Alert>
            :
                <VariableSetsTable
                    variableSets={variableSets}
                />
            }
            <Spacer size={4} />
            <h4 className="h5">Custom Variables In Project {project.name}</h4>
            { !variables || !variables.length ?
                <Alert variant="info">No Custom Variables have been created in this project yet!</Alert>
            :
                <VariablesTable
                    id="custom-variables-table"
                    variables={variables}
                />
            }
        </InnerPage>
    )
}
