import { VariablesSearch } from '../../components/Variables';
import { InnerPage } from '../../layout';

export const AllVariables = () => {

    return (

        <InnerPage
            title="Variables Catalog"
            intro="The Variables Catalog is an easy way to search through the thousands of variables in our database by keyword, review details on each, and compare variables to eachother. You'll find Variables Catalog functionality featured throughout DIVER any time a variable needs to be selected for a given task."
        >
            <p>DIVER includes harmonized instrument variables (most of those present), distribution-level variables (prefixed with "dist_"), and variables created for higher-level harmonizations (prefixed with "NRGR_"). More on the differences between these can be found in DIVER Help.</p>

            <VariablesSearch
                noTitle={true}
            />

        </InnerPage>
    
    )
}
