import { ConstructMappingTable } from '../../components/ClinicalConstructs';
import { CreateClinicalConstructPage } from '../../layout';
import { VARIABLE_TYPES } from '../../utils/constants';


export const NewTwoVarConstruct = () => {

    const variableLimit = 2;

    return (

        <CreateClinicalConstructPage
            title="Combine Two Variables"
            intro="Creating a new Custom Variable via Combining Variables is done by selecting the two variables to combine, specifying what values your new variable should have, and then filling in a table showing what values your new variable should have given possible values of the two original variables."
            construct_type="merge"
            variableLimit={variableLimit}
            alwaysHaveUnknown
            simple
            allowedTypes={[VARIABLE_TYPES.CATEGORICAL, VARIABLE_TYPES.DICHOTOMOUS, ]}
        >

            <ConstructMappingTable />

        </CreateClinicalConstructPage>
    
    
    )    
}
