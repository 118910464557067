import { useEffect, useState } from "react";
import { DowncodeRow } from "."
import { Button } from "../Buttons";
import { Icon } from "../GeneralUI";
import { useClinicalConstructContext } from "../../contexts";


export const DowncodeTable = () => {

    // Context values
    const { 
        setConstraints : setContextConstraints, 
        variables,
        values
    } = useClinicalConstructContext();

    const variable = variables ? variables[0] : null;
    
    const [ constraints, setConstraints] = useState([]);
    const [ selectedValues, setSelectedValues] = useState(['']); // start with one row
            // FIXME: the above is probably also where we should init
            // selectedValues on occasions when values are already provided
            // (read: editing existing)

    // Reset constraints if variable changes
    useEffect(() => {
        if (variables && variables[0] && variables[0].data_type) {
            setConstraints([{}])
        }
    }, [variables])

    // Set the values in Context
    useEffect(() => {

        // Rebuild whole constraints array every time because it's not that costly and 
        // it's easier than trying to keep track of all the individual parts
        const newConstraints = []

        values.forEach((val, valIndex) => {

            newConstraints[valIndex] = {
                relationship: "OR",
                constraints: constraints
                                .map((c, i) => ({
                                    ...c,
                                    order: i // add order in so that constraints in table can be processed from top down
                                }))
                                .filter((c,i) => (
                                    selectedValues[i] === val.name
                                    //parseInt(selectedValues[i]) === valIndex // get only the constraints for this value
                                ))
            }

        })

        setContextConstraints(newConstraints)

    }, [constraints, selectedValues, setContextConstraints, values])

    const handleAddRow = () => {
        const newSelectedValues = [...selectedValues];
        newSelectedValues.push('')
        setSelectedValues(newSelectedValues);
    }

    const handleRemoveRow = (i) => {
        const newSelectedValues = [...selectedValues];
        newSelectedValues.splice(i, 1)
        setSelectedValues(newSelectedValues);
    }

    const setConstraint = (constraint, i) => {
        const newConstraints = [...constraints];

        if (newConstraints[i] !== constraint) {
            newConstraints[i] = constraint;
            setConstraints(newConstraints);
        }
    }

    const setSelectedValue = (v, i) => {
        const newSelectedValues = [...selectedValues];

        if (newSelectedValues[i] !== v) {
            newSelectedValues[i] = v;
            setSelectedValues(newSelectedValues);
        }
    }

    if (!variable || values.filter(v => v.name.trim()).length <= 1 || !variable.data_type) {
        return <></>;
    }

    return (
    
        <section className="my-5">

            <h4 className="h6">Downcode Variable Values</h4>
            
            <p>The value "[UNK]" (for "Unknown") is automatically provided for you. If you want to assign a value you did not already define, you can scroll up and add it. If you defined any values that end up not assigned in the table, you will have to scroll up and delete them before the "Save Custom Variable" becomes active.</p>

            <div className="table-scroll">
                <div className="table-scroll__wrapper">
                    <table className="w-100 table downcode-table">

                        {/* Table Header */}
                        <thead>
                            <tr>
                                <th>Original Value</th>
                                <th>New Value</th>
                                <th>
                                    <span className="sr-only">Actions</span>
                                </th>
                            </tr>
                        </thead>

                        {/* Table Body */}
                        <tbody>
                        
                            { selectedValues.map((selectedVal, i) => (
                                
                                <DowncodeRow
                                    key={i}
                                    index={i}
                                    selectedValue={selectedVal}
                                    setSelectedValue={setSelectedValue}
                                    setConstraint={setConstraint}
                                    removeRow={() => handleRemoveRow(i)}
                                /> 
                            
                            ))}

                            <DowncodeRow 
                                isDefault 
                            />

                        
                        </tbody>

                    </table>
                </div>
            </div>

            <Button
                size="md" 
                variant="secondary"
                classes="font-weight-600"
                uppercase
                onClick={handleAddRow}
            >
                Add Row <Icon name="plus-circle" />
            </Button>

        </section>

    )
}
