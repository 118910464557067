import { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
//import PropTypes from "prop-types";

import { VariablesSearch } from "./";
import { CustomVariables } from "../ClinicalConstructs";
import { RadioInput } from "../FormElements";
import { Button } from "../Buttons";
import { getPathFromRouteName } from '../../utils';
import { VARIABLE_TYPES, NEW_DOWNCODE } from "../../utils/constants";

export const VariablesSelect = ({ noTitle, handleClose, hide, variableActions, allowedTypes }) => {

    const [ selectType, setSelectType ] = useState('search');
    const [ searchHide, setSearchHide ] = useState();
    const [ constructsHide, setConstructsHide ] = useState();
    
    // switch forms when selectType is set
    useEffect(() => {
        if (selectType === "search") {
            setConstructsHide(true);
            setSearchHide(false);
        } else {
            setSearchHide(true);
            setConstructsHide(false);
        }
    }, [selectType, setConstructsHide, setSearchHide])

    return (
        <section className={hide ? 'd-none' : ''}>
            {!noTitle && <h3 className="h5 mb-0 mr-3">Select Variables</h3> }
            {handleClose && <Button variant="primary" size="xs" onClick={handleClose}>Close</Button> }
            
            {allowedTypes &&
                    (allowedTypes.every(type => [VARIABLE_TYPES.CATEGORICAL, VARIABLE_TYPES.DICHOTOMOUS].indexOf(type) > -1)) ?
                    // special case for categorical and dichotomous only
                    <p>NOTE: This function only works with categorical variables; non-categorical variables will not show in search results here. If you have a non-categorical variable you're interested in, you can <strong>make</strong> it categorical by <Link to={getPathFromRouteName(NEW_DOWNCODE)}>creating a downcoded Custom Variable with it</Link>.</p>
                    :
                    <p>NOTE: This function only works with particular variable types; variables not of those types will not show in search results here.</p>
            }
            
                <div className="row">
                    <div className="col-12 py-2">
                        
                        <RadioInput
                            name="search"
                            onChange={setSelectType}
                            selectedOption={selectType}
                            value="search"
                            label="Search Variables Catalog by Keyword or Variable Name"
                        />
                        <RadioInput
                            name="construct"
                            onChange={setSelectType}
                            selectedOption={selectType}
                            value="construct"
                            label="Select one of your Custom Variables"
                        />
                    </div>
                </div>
            
            <VariablesSearch
                variableActions={variableActions}
                noTitle
                allowedTypes={allowedTypes}
                hide={searchHide}
            />
            
            <CustomVariables
                variableActions={variableActions}
                noTitle
                allowedTypes={allowedTypes}
                hide={constructsHide}
            />
        </section>
    )
}

/*VariablesSelect.propTypes = {
    hide: PropTypes.bool,
    variableActions: PropTypes.arrayOf(
        PropTypes.oneOfType([PropTypes.object,  PropTypes.func])
    ),
}*/
