import { useState } from "react"
//import PropTypes from "prop-types";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";

import { Button, IconButton } from "."
import { Modal } from "../GeneralUI/Modal";
import { getPathFromRouteName } from "../../utils";
import { useAppState } from "../../hooks";

/**
 * Delete button + modal for deleting entities
 */
export const DeleteButton = ({ deleteFn, entityLabel, id, modal, returnRoute }) => {

    const [ modalOpen, setModalOpen ] = useState(false);
    const [ itemDeleting, setItemDeleting ] = useState(false);
    const navigate = useNavigate();
    const { errorCheckText } = useAppState();

    const confirmDelete = () => {

        if (id && deleteFn) {
            
            setItemDeleting(true);
            deleteFn((id))
                .then(res => {
                    
                    setItemDeleting(false);
                    setModalOpen(false);
                    if (returnRoute) {
                        navigate(getPathFromRouteName(returnRoute))
                    }
                    else {
                        navigate(0)
                    }
                })
                .catch(err => {
                    toast.error(errorCheckText(err, `Unable to delete ${entityLabel}.`));
                    setItemDeleting(false);
                    setModalOpen(false);
                })
        }
    }


    return (
        <>

            <IconButton 
                iconName="trash-alt"
                onClick={() =>  setModalOpen(true)} 
                tooltip={`Delete ${entityLabel}`}
                variant="outline"
            />

            {modalOpen &&
                <Modal
                    title={modal.title}
                    handleClose={() => setModalOpen(false)}
                    action={
                        <>
                            <Button
                                size="sm"
                                variant="primary"
                                onClick={() => setModalOpen(false)}
                            >
                                Cancel
                            </Button>

                            <Button
                                variant="danger"
                                onClick={confirmDelete}
                                disabled={modal.button ? modal.button.disabled : false}
                                buttonLoading={itemDeleting}
                            >
                                <span className="btn--text">
                                    Delete
                                </span>
                            </Button>
                        </>
                    }
                >

                    {modal.body}

                </Modal>
            }

        </>
    )
}


/*DeleteButton.propTypes = {
    entityLabel: PropTypes.string.isRequired,
    id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]).isRequired,
    message: PropTypes.node,
    returnRoute: PropTypes.string,
}*/
