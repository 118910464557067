import { Fragment, useCallback, useEffect } from "react"
//import PropTypes from "prop-types";

import { Button, IconButton } from "../Buttons";
import { Select } from '../FormElements';
import { Icon } from "../GeneralUI";
import { ConstraintForm } from "../Constraints";
import { useClinicalConstructContext } from "../../contexts";
import { VARIABLE_TYPE_CONSTRAINTS } from "../../utils/constants";

export const MultiLineConstraint = ({
    addConstraintRow: _addConstraintRow,
    constraintGroup,
    gutterSize,
    index,
    readonly,
    updateConstraintGroup,
}) => {

    const { variables } = useClinicalConstructContext();

    const { constraints } = constraintGroup;

    const variable = (constraintGroup.constraints[0] && variables.find(v => v.variable_name === constraintGroup.constraints[0].variable_name)) || variables[0];

    const defaultConstraintType = variable.data_type ? VARIABLE_TYPE_CONSTRAINTS[variable.data_type][0] : '';


    const setNewConstraintsInGroup = useCallback((name, value, constraintIndex) => {
        const newConstraints = [...constraintGroup.constraints];
        
        if (newConstraints[constraintIndex][name] !== value) {

            newConstraints[constraintIndex][name] = value;
            updateConstraintGroup('constraints', newConstraints, index)

        }

    }, [constraintGroup.constraints, index, updateConstraintGroup])

    useEffect(() => {
        if (variable.data_type && (!constraints[0] || !constraints[0].constraint_type)) {
            setNewConstraintsInGroup('constraint_type', defaultConstraintType, 0)
        }
    }, [variable, constraints, defaultConstraintType, setNewConstraintsInGroup])


    const setConstraintType = useCallback((v, i) => {
        setNewConstraintsInGroup('constraint_type', v, i)
    }, [setNewConstraintsInGroup])

    const setConstraintValue = useCallback((v, i) => {
        setNewConstraintsInGroup('constraint_value', v, i)
    }, [setNewConstraintsInGroup])

    const setConstraintVariableName = useCallback((v) => {
        const newConstraints = constraintGroup.constraints.map(c => ({
            ...c,
            variable_name: v
        }));
        
        updateConstraintGroup('constraints', newConstraints, index)
    
    }, [constraintGroup.constraints, index, updateConstraintGroup])
    
    // This is HYPERCRITICAL for situations in which, when const variable above
    // is set, it ends up passing through to the "|| variables [0]" case.
    // Because in that case, we're arbitrarily picking the first variable of
    // our available variables, but *not telling the constraint group we did
    // this*. Therefore they get out of sync, and Bad Things Happen. So here we
    // just make sure this case is handled and the constraintGroup is properly
    // informed. (Otherwise you get fun things like, say, variable selections
    // not being sent to the backend when trying to save!)
    useEffect(() => {
        if (variable && !constraintGroup.constraints[0].variable_name) {
            setConstraintVariableName(variable.variable_name)
        }
    }, [variable, constraintGroup.constraints, setConstraintVariableName])

    const setRelationship = (v) => {
        updateConstraintGroup('relationship', v, index)
    }

    
    const addConstraintRow = () => {
        _addConstraintRow(index, variable)
    }

    const removeConstraintRow = (i) => {
        const newConstraints = [...constraintGroup.constraints];
        newConstraints.splice(i, 1)
        updateConstraintGroup('constraints', newConstraints, index)
    }

    //console.log("constraint group variable name 0 " + constraintGroup.constraints[0].variable_name);

    if (!variable) return '';

    return (
        <div className="row">

            <div className={`col-12 col-md-6 col-lg-3 align-items-center d-flex px-${gutterSize}`}> 
                
                {/*console.log("Rendering variable Select; variable name is " + variable.variable_name)*/}
                {readonly ? 
                
                    // Display variable thate constrainted this group 
                    variable.variable_name
                
                :
                    // Choose variable to constraint in this group 
                    <Select 
                        label="Choose variable"
                        hideLabel
                        name="variable_name"
                        value={variable.variable_name}
                        onChange={setConstraintVariableName}
                        options={variables.map(v => v.variable_name)}
                    />
                }

            </div>

            {/* Each of these is a "row" in the complex constraint */}
            {constraints.map((constraint, i) => (
                <Fragment key={i}>
                    {

                        i !==0 &&

                            <div className={`col-12 col-md-6 col-lg-3 align-items-center d-flex pl-5 pr-${gutterSize}`}> 
                                
                                {/* Choose the relationship for this group, i.e. AND/OR */}
                                {/*console.log("Rendering relationship Select; relationship is " + constraintGroup.relationship)*/}
                                <Select 
                                    assignDefault
                                    label="Relationship"
                                    hideLabel
                                    name={`relationship-${i}`}
                                    disabled={i > 1}
                                    hideArrow
                                    value={constraintGroup.relationship}
                                    onChange={setRelationship}
                                    options={["AND", "OR"]}
                                />
                            
                            </div>    
                        
                    }


                    {/* The actual constraint definition */}
                    {constraint.constraint_type && 
                        <ConstraintForm
                            index={i}
                            variable={variable}
                            constraintType={constraint.constraint_type}
                            setConstraintType={setConstraintType}
                            constraintValue={constraint.constraint_value}
                            setConstraintValue={setConstraintValue}
                            gutterSize={gutterSize}
                            removeButton={
                                constraints.length <= 1 ? null : 

                                    <IconButton
                                        classes={"btn-md"}
                                        variant="info"
                                        iconWeight={400}
                                        onClick={() => removeConstraintRow(i)}
                                        aria-label={`Delete Constraint ${i}`}
                                        tooltip="Remove constraint"
                                        iconName="trash-alt"
                                    />

                            }
                        />
                    }

                    {/* For spacing between rows */}
                    <div className="col-12 mb-3"></div>

                </Fragment>
            ))}


            {/* Add another constraint row to this group */}
            <div className={`col-12 col-md-6 col-lg-3 pl-5 pr-${gutterSize}`}>

                <Button
                    classes="w-100 p-2"
                    variant="secondary"
                    size="sm"
                    uppercase
                    onClick={addConstraintRow}
                >
                    <Icon name="level-down-alt" /> <small className="font-weight-600">Constrain further</small>
                </Button>

            </div>
    
        </div>
    )
}

/*MultiLineConstraint.propTypes = {
    addConstraintRow: PropTypes.func,
    constraintGroup: PropTypes.object.isRequired,
    gutterSize: PropTypes.number,
    index: PropTypes.number.isRequired,
    readonly: PropTypes.bool,
    updateConstraintGroup: PropTypes.func,
}*/
