import { useEffect, useState } from "react";
//import PropTypes from "prop-types";

import { condClass } from "../../utils/conditionalClass";

let count = 0;

const getOptionValue = (opt) => {
    if (typeof opt === 'string') {
        return opt
    } else {
        return opt.value;
    }
}


export const Select = ({ assignDefault = false, classes, helpText, hideArrow, hideLabel, label, name, onChange, options, value, ...rest }) => {

    // Generates automatic unique id
    const [ id ] = useState(('select-' + count++));

    const handleChange = e => {
        onChange(e.target.value, name);
    }

    /**
     * If "assignDefault" is true, automatically set default value to parent 
     * on first component render
     * Warning: running "onChange" on first render can lead to infinite rerenders
     * If using "assignDefault", make sure "onChange" is defined with useCallback
     **/ 
    useEffect(() => {

        if (assignDefault) {

            // If there is already a value set/provided, we don't want to override it
            if (value) {
                return;
            }

            // If there are no options, this isn't going to work
            if (!options || !options.length) {
                return;
            }

            const option = options[0];
            onChange(getOptionValue(option), name);

        }

    }, [assignDefault, name, options, onChange, value])

    return (
        <>

            {!hideLabel && label && 
                <label htmlFor={id}>{label}</label>
            }

            <select 
                id={id} 
                aria-label={hideLabel && label}
                name={name}
                value={value === null || typeof value === "undefined" ? getOptionValue(options[0]) : value}
                onChange={handleChange}
                className={`form-control${condClass(classes, classes)}${condClass(hideArrow && rest.disabled, 'hide-arrow')}`}
                aria-describedby={`${helpText ? id + "-help " : ""}` || null}
                {...rest}
            >
                {
                    options.map((option, i) => {
                        let opt = option;

                        // allows options to be passed as string or object 
                        if (typeof option === 'string') {
                            opt = {
                                value: option,
                                name: option,
                            }
                        }

                        return (
                            <option 
                                key={i}
                                value={opt.value}
                                disabled={!!opt.disabled}
                            >
                                {opt.name}
                            </option>
                        )
                    })
                }
            </select>


            {/* Help text */}
            {helpText &&
                <small id={`${id}-help`} className="form-text show">{helpText}</small>
            }

        </>
    )
}


/*Select.propTypes = {
    assignDefault: PropTypes.bool,
    classes: PropTypes.string,
    helpText: PropTypes.string,
    hideArrow: PropTypes.bool,
    hideLabel: PropTypes.bool,
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(
        PropTypes.oneOfType([
            PropTypes.shape({
                name: PropTypes.oneOfType([
                    PropTypes.string,
                    PropTypes.number,
                ]),
                value: PropTypes.oneOfType([
                    PropTypes.string,
                    PropTypes.number,
                ]),
            }),
            PropTypes.string
        ])
    ).isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]).isRequired,
}*/
