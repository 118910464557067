import { Link } from "react-router-dom"

import { Alert, Spacer } from "../../components/GeneralUI"
import { usePageTitle } from "../../hooks"
import { InnerPage } from "../../layout"
import { getPathFromRouteName } from "../../utils"
import { HOME } from "../../utils/constants"

export const NotFound = () => {

    usePageTitle("Not Found")

    return (
        <InnerPage
            title={"Page Not Found"}
        >

            <Alert>
                The page you were looking for could not be found. 
                <Spacer size={2} />
                <Link to={getPathFromRouteName(HOME)}>Return to the DIVER Dashboard.</Link>
            </Alert>

        </InnerPage>
    )
}