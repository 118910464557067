import { useCallback, useEffect, useState } from "react";
//import PropTypes from "prop-types";

import { MultiLineConstraint } from "."
import { usePrevious } from "../../hooks";
import { addItemToArray, removeItemFromArray } from "../../utils";
import { VARIABLE_TYPE_CONSTRAINTS } from "../../utils/constants";
import { Button, IconButton } from "../Buttons";
import { Select } from "../FormElements";

const getDefaultConstraintGroup = (variable) => ({
    // NOTE: I'm taking a good-enough-for-gander approach here. By any sane
    // measure, our constraint widgets should update themselves when a
    // relationship is pre-populated - i.e. defaults should NOT be hardcoded
    // here. We've been hardcoding "relationship" and blithely ignoring
    // variable, and that's causing bugs. No longer.
    relationship: "",
    constraints: [getDefaultConstraintRow(variable)]
})

const getDefaultConstraintRow = (variable) => (
    {
        constraint_type: variable && variable.data_type ? VARIABLE_TYPE_CONSTRAINTS[variable.data_type][0] : '',
        constraint_value: null,
        variable_name: (variable && variable.variable_name) || "",
    }
)


export const ComplexValueDefinition = ({ index, setValueConstraints }) => {
    
    const gutterSize = 2;

    const [ relationship, setRelationship ] = useState('');
    const [ constraintGroups, setConstraintGroups] = useState([])
    

    const prevConstraintGroups = usePrevious(constraintGroups)
    const prevRelationship = usePrevious(relationship)

    // Pass all constraint groups up to get set in context by ConstructValue
    useEffect(() => {

        if (constraintGroups !== prevConstraintGroups || relationship !== prevRelationship) {

            const constraints = {
                constraints: constraintGroups,
                relationship: constraintGroups.length > 1 ? relationship : ""
            }
    
            setValueConstraints(constraints, index);
        }

    }, [ 
        constraintGroups,
        index,
        prevConstraintGroups,
        prevRelationship,
        relationship,
        setValueConstraints,
    ])

    // Set constraint group when there is none, based on the variable
    useEffect(() => {


        if (constraintGroups.length === 0) {
            const defaultGroup = getDefaultConstraintGroup()
            setConstraintGroups([defaultGroup])
        }
        
    }, [ constraintGroups.length ])


    const addConstraintGroup = useCallback(() => {
    
        const newConstraintGroup = getDefaultConstraintGroup();
        
        addItemToArray(newConstraintGroup, constraintGroups, setConstraintGroups)
    
    }, [ constraintGroups ]);


    const addConstraintRow = useCallback((i, variable) => {

        const newConstraintRow = getDefaultConstraintRow(variable);
        const newConstraintGroups = [...constraintGroups];
        
        newConstraintGroups[i].constraints.push(newConstraintRow);
        
        setConstraintGroups(newConstraintGroups)
        
    }, [ constraintGroups ]);


    const updateConstraintGroup = useCallback((name, value, index) => {
        const newConstraintGroups = [...constraintGroups];

        newConstraintGroups[index] = {
            ...constraintGroups[index],
            [name]: value,
        }
        
        setConstraintGroups(newConstraintGroups)
    }, [constraintGroups])


    const removeConstraintGroup = useCallback((i) => {
        if (constraintGroups.length <= 1) return;

        removeItemFromArray(i, constraintGroups, setConstraintGroups)
       
    }, [constraintGroups])

    return (
        <div>
            <div className="font-weight-600 mt-3 mb-2">Filters</div>


            {/* This contains the whole definition for a complex value 
             It has the gray border in the UI */}
            <div className="complex-value-definition">

                {
                    constraintGroups.map((row, i) => (
                
                        <div key={i} className="complex-value-definition__var">
                            <div className={`container px-${gutterSize}`}>
                                {
                                    i !== 0 &&
                                        <div className="row mb-3">
                                            <div className={`col-12 col-sm-6 col-md-3 align-items-center px-${gutterSize}`}> 
                                            
                                                <Select
                                                    assignDefault
                                                    disabled={i > 1}
                                                    name="relationship"
                                                    hideArrow
                                                    value={relationship}
                                                    onChange={setRelationship}
                                                    options={["AND", "OR"]}
                                                />
                                            
                                            </div>    
                                        </div>
                                }
                                
                                {/* 
                                * Each of these is a "group" constraining one variable; 
                                * They have the light blue background in the UI 
                                */}
                                <MultiLineConstraint 
                                    index={i}
                                    gutterSize={gutterSize} 
                                    constraintGroup={row}
                                    addConstraintRow={addConstraintRow}
                                    updateConstraintGroup={updateConstraintGroup}
                                />

                                <div className="complex-value-definition__delete-group">

                                    {/* Removes the group */}
                                    <IconButton
                                        disabled={constraintGroups.length <= 1}
                                        variant="info"
                                        onClick={() => removeConstraintGroup(i)}
                                        aria-label={`Delete Value ${i}`}
                                        tooltip="Remove value"
                                        iconName="trash-alt"
                                    />
                                </div>
                            </div>
                        </div>
                    ))
                }

                {/* Adds a whole group */}
                <Button
                    className="mx-auto d-block font-weight-600"
                    variant="secondary"
                    size="md"
                    uppercase
                    onClick={addConstraintGroup}
                >
                    Constrain another variable
                </Button>
            </div>
        </div>
    )
}

/*ComplexValueDefinition.propTypes = {
    index: PropTypes.number.isRequired,
    setValueConstraints: PropTypes.func.isRequired
}*/
