import { createContext, useContext } from 'react';

export const ClinicalConstructContext = createContext({
    defaultValue: false,
    readonly: false,
    variables: [], 
    values: [], 
    setConstraints: () => {},
    setDefaultValue: () => {}, // only for downcode variable
    setValues: () => {},
});

export const useClinicalConstructContext = () => useContext(ClinicalConstructContext)