import { Fragment, useEffect, useState } from "react";
//import PropTypes from "prop-types";

import { usePrevious } from "../../hooks";
//import { variableShape } from "../../utils";
import { CONSTRAINT_TYPES, VARIABLE_TYPES } from "../../utils/constants";
import { ConstraintDateInput, NumberInput, Select, TextInput } from "../FormElements";

// Gets input to render based on innate variable type and user-chosen constraint type
const getInput = (variableType, constraintType) => {

    if (constraintType === "ANYOF" ) {
        return TextInput;
    }
    
    switch (variableType) {
        case VARIABLE_TYPES.DICHOTOMOUS: 
        case VARIABLE_TYPES.CATEGORICAL: 
        case VARIABLE_TYPES.MULTICATEGORY: 
            if (constraintType === "EQ" || constraintType === "NEQ") {
                return Select;
            }
            return TextInput;
        case VARIABLE_TYPES.TEXT: 
        case VARIABLE_TYPES.NULL: 
            return TextInput;
        case VARIABLE_TYPES.DATE: 
            return ConstraintDateInput;
        case VARIABLE_TYPES.NUMBERRANGE: 
            return NumberInput;
        default:
            return Fragment;
    }

}

export const ConstraintInput = ({ constraintType, setConstraintValue, variable }) => {

    const [ value1, setValue1 ] = useState('');
    const [ value2, setValue2 ] = useState('');
    
    const prevV1 = usePrevious(value1);
    const prevV2 = usePrevious(value2);

    const valuesLength = CONSTRAINT_TYPES[constraintType].valCount !== null ? CONSTRAINT_TYPES[constraintType].valCount : 1;

    // Reset initial values when data type changes
    useEffect(() => {
        
        setValue1('');
        setValue2('');
        
    }, [ variable.data_type ])
    
    // Set the constraint value in parent
    useEffect(() => {

        if (!valuesLength) {
            setConstraintValue(null)
            return;
        }

        // This component has a tendency to rerender often, causing this useEffect to enter a loop
        // Compare current values to previous to determine if they actually need to be updated again
        if (prevV1 === value1 && prevV2 === value2) {
            return;
        }

        if (valuesLength > 1) {
            setConstraintValue([value1, value2])
        } else {
            setConstraintValue(value1)
        }
    }, [ setConstraintValue, value1, value2, prevV1, prevV2, valuesLength ])

    // Return a spacer element if we don't need the actual component
    if (!valuesLength) {
        return <div className="row px-2"></div>;
    }

    // get correct Input type according to variable and constraint types
    const Input = getInput(variable.data_type, constraintType);

    const extraProps = Input => {
        const selectProps = {};

        if (Input === Select) {
            selectProps.assignDefault = true;
	    selectProps.options = variable.value_mapping ? variable.value_mapping.map((v,i) => ({name: v, value: variable.possible_values[i]})) : []
        }

        return selectProps;
    }

    return (
        <>
            
            <Input
                classes="flex-grow-1"
                hideLabel
                label="First Constraint Value"
                name="constraint_value0"
                value={value1}
                helpText={CONSTRAINT_TYPES[constraintType].helpText}
                onChange={setValue1}
                {...extraProps(Input)}
            />

            {/* 
             * Used if there are multiple constraint inputs, i.e. for "Is Between" 
             * Note: "ANY" uses a single input, separated by commas, because we can't determine how many there should be total
             * And if we allowed "+/-" buttons, the UI would become overwhelming
             */}
            {
                valuesLength > 1 &&
                   
                    <>
                        <span className="px-2">and</span>
                            <Input
                                classes="flex-grow-1"
                                hideLabel
                                label="Second Constraint Value"
                                name="constraint_value1"
                                value={value2}
                                onChange={setValue2}
                            />
                    </>

            }
        </>
    )
}

/*ConstraintInput.propTypes = {
    constraintType: PropTypes.string.isRequired,
    setConstraintValue: PropTypes.func.isRequired,
    variable: PropTypes.shape(variableShape).isRequired,
}*/
