// Table of functions for validating text input fields of various types.

// 'validate' is a function that's called with the candidate value when the
// TextInput loses focus.
// 'regex' is a regular expression that *every possible change* to the
// candidate value is matched against before 
// 
// NOTE: It is VERY IMPORTANT that any length restrictions or the like be
// handled in 'validate' rather than the regex, because otherwise you'll end up
// making it impossible for the user to enter their value! A regex should match
// not just what's acceptable for a final name, but any transitional states
// towards that final name.

export const textFieldValidators = {
    variable_name: {
            validate: (val) => {
                if (val.length < 2) {
                    return "Variable name is not long enough; minimum 3 characters."
                }
                // FIXME: I'd love to do a name lookup here, but that'd possibly
                // require a restructuring...
                return false;
            },
            regex: /(^$|^([a-zA-Z_]([a-zA-Z0-9_]{0,47}))$)/,
            helptext: "Name may contain up to 48 letters, numbers, or underscores (minimum 3); cannot start with a number."
            },
    cohort_name: {
            validate: () => { return false },
            regex: new RegExp(/^([^'"\\]{0,48})$/),
            helptext: "Name may contain up to 48 characters; no quotes (single or double) or backslashes."
            },
    varset_name: {
            validate: () => { return false },
            regex: new RegExp(/^([^'"\\]{0,64})$/),
            helptext: "Name may contain up to 64 characters; no quotes (single or double) or backslashes."
            },
    project_name: {
            validate: () => { return false },
            regex: new RegExp(/^([^'"\\]{0,32})$/),
            helptext: "Name may contain up to 32 characters; no quotes (single or double) or backslashes."
            },
    customvar_value: {
            validate: () => { return false },
            regex: /^([a-zA-Z0-9_]{0,48})$/,
            helptext: "Value may contain up to 48 letters, numbers, or underscores."
            },
    date_constraint: {
            validate: (val) => {
                if (val.length < 10) {
                    return "Date is not long enough."
                }
        
                if (val.length > 10) {
                    return "Date is too long."
                }
                return false;
            },
            regex: new RegExp(/^(([\du]?)|([\du]{2})|([\du]{3})|([\du]{4})|([\du]{4}-)|([\du]{4}-[\du])|([\du]{4}-[\du]{2})|([\du]{4}-[\du]{2}-)|([\du]{4}-[\du]{2}-[\du])|([\du]{4}-[\du]{2}-[\du]{2}))$/i),
            helptext: "Date should be in the format YYYY-MM-DD, with UU substituted for any unknown digits."
            },
    };
