import { useCallback, useState } from 'react';

import { TextInput } from '../FormElements'
import api from '../../utils/api'
import { DeleteButton } from '../Buttons';

/**
 * Delete button + confirmation modal for deleting variable sets
 */
export const DeleteVariableSetButton = ({ varset, returnRoute }) => {
    
    const [ name, setName ] = useState("")

    const handleNameChange = useCallback((val) => setName(val), [])

    return (
        <DeleteButton
            id={varset.id}
            entityLabel="Variable Set"
            deleteFn={api.deleteVariableSet}
            returnRoute={returnRoute}
            modal={{
                title: "Delete Variable Set?",
                button: {
                    disabled: name !== varset.name
                },
                body: (
                    <>
                        <p>Please confirm that you’d like to delete <strong>{varset.name}</strong> by typing the Variable Set name <strong>{varset.name}</strong> below.</p>
                        
                        <TextInput
                            required
                            label="Variable Set Name"
                            value={name}
                            name="delete-confirm-name"
                            onChange={handleNameChange}
                        />
                        
                        <p>Note that in some cases deletion can take several minutes. Please be patient.</p>
                    </>
                ),
            }}
        />
    )
}
