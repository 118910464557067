import { useState } from "react";
import { Link } from "react-router-dom";
//import PropTypes from "prop-types";

import { condClass } from "../../utils";
import { Icon, SvgIcon, Tooltip } from "../GeneralUI";
import { ConditionalWrapper } from "../Utilities";


const getClasses = (variant, additionalClasses) => {
    let buttonClasses = "btn btn--icon mx-1";

    switch (variant) {
        case 'secondary':
            buttonClasses += " btn-secondary";
            break;
        case 'info': 
            buttonClasses += " btn--primary-blue";
            break;
        case 'outline': 
            buttonClasses += " btn--outline btn-primary btn--primary-blue";
            break;
        default:
            break;
    }
    
    if (additionalClasses) {
        buttonClasses += ` ${additionalClasses}` ;
    }

    return buttonClasses

}


export const IconButton = ({ classes, disabled, fontAwesome = true, iconName, iconWeight, newTab, to, tooltip, onClick, variant }) => {

    const [ showTooltip, setShowTooltip ] = useState(false);

    const toggleTooltipOn = () =>  {
        setShowTooltip(true);
    }
    
    const toggleTooltipOff = () => {
        setShowTooltip(false);
    } 

    const commonProps = {
        onFocus: toggleTooltipOn,
        onBlur: toggleTooltipOff,
        onMouseEnter: toggleTooltipOn,
        onMouseLeave: toggleTooltipOff,
        className: `${getClasses(variant, classes)}${condClass(disabled, 'disabled')}`,
        'aria-label': tooltip + (newTab ? ". Opens in new tab" : "")
    }
  
    return (

        <div className="position-relative">

            {/* Button/Link */}
            <ConditionalWrapper
                condition={!to}
                wrapper={children => (
                    <button 
                        onClick={onClick}
                        type="button" 
                        {...commonProps}
                        disabled={disabled}
                    >
                        {children}
                    </button>
                )}
                altWrapper={children => (
                    <Link 
                        to={to}
                        target={newTab ? "_blank" : null}
                        {...commonProps}
                    >
                        {children}
                    </Link>
                )}
            >
                {fontAwesome ? 

                    // Font Awesome icon
                    <Icon 
                        name={iconName}
                        weight={iconWeight}
                        style={{width:18}}
                        classes="d-flex justify-content-center"
                    />

                :

                    // Custom icon
                    <div style={{margin: -5}}>
                        <SvgIcon 
                            name={iconName}
                            size={28}
                        />
                    </div>
                    
                }
            </ConditionalWrapper>
        

            {tooltip &&
            
                // Tooltip 
                <Tooltip show={showTooltip}>
                    {tooltip} {newTab ? <i className="pl-1 fas fa-external-link-alt"></i> : ""}
                </Tooltip>
               
            }

        </div>
        
    )
    
}


/*IconButton.propTypes = {
    classes: PropTypes.string,
    disabled: PropTypes.bool,
    fontAwesome: PropTypes.bool,
    iconName: PropTypes.string,
    iconWeight: PropTypes.oneOf([
        "solid",
        "s",
        700,
        "700",
        "regular",
        "r",
        400,
        "400",
        "light",
        "l",
        300,
        "300", 
    ]),
    newTab: PropTypes.bool,
    to: PropTypes.string,
    tooltip: PropTypes.string,
    onClick: PropTypes.func,
    variant: PropTypes.string,
}*/
