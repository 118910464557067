import { useState } from 'react';
//import PropTypes from 'prop-types';

import { Button } from '../Buttons';
import { TextInput } from '../FormElements';
import { Icon } from '../GeneralUI'
//import { variableShape } from '../../utils';

/**
 * Form for editing a limited subset of variable fields - explicit context,
 * question text, implicit context, and answer meanings.
 * Changes are NOT immediately added to the database.
 */
export const VariableFieldEditForm = ({
    variable,
    hide,
    onReset,
    onSubmit,
    setExplicit: _setExplicit, 
    setQuestion: _setQuestion,
    setImplicit: _setImplicit,
    setMappings: _setMappings
}) => {
    
    // Default mappings value needs to be figured out upfront...
    const defaultMappings = Object.fromEntries(
            variable.possible_values.map((value, idx) => {
            return [value, variable.value_mapping[idx]] }));

    const [explicit, setExplicit] = useState(variable.explicit);
    const [question, setQuestion] = useState(variable.question);
    const [implicit, setImplicit] = useState(variable.implicit);
    const [mappings, setMappings] = useState(defaultMappings);
    const [comment, setComment] = useState("");

    const handleMappingUpdate = (value, name) => {
        let newMappings = Object.assign({}, mappings);
        newMappings[name] = value;
        setMappings(newMappings);
    }
    
    const displayTextField = field => {
        return (field !== "-" ? field : "");
    };
    const updateTextField = func => {
        return (e => {
            if (e !== "") {
                func(e);
            } else {
                func("-");
            }
        })};

    // Set back to defaults on reset
    const handleReset = e => {
        e.preventDefault();

        setExplicit(variable.explicit)
        setQuestion(variable.question)
        setImplicit(variable.implicit)
        setMappings(defaultMappings)
        setComment("")

        if (onReset)
            onReset();        
    }

    // Prevent refresh and pass values to parent on submit
    const handleSubmit = e => {
        e.preventDefault();
        
        if (onSubmit){
            onSubmit({
                variable_name: variable.variable_name,
                explicit,
                question,
                implicit,
                values: mappings,
                comment
            });
        } 
    }

    return (
        
        <section className={hide ? 'd-none' : 'section--blue'}>
            <form onSubmit={handleSubmit} onReset={handleReset}>
                
                <p className="my-0">Variable Question Text has three parts to it:</p>
                <ul className="my-0 ml-4">
                    <li className="my-0">Explicit Context: text in the interview that is not directly part of the question, but gives context as to what is being asked.</li>
                    <li className="my-0">Question Text: the literal text of a question, unique to that question.</li>
                    <li className="my-0">Implicit Context: text NOT in the instrument, added by the transcriber to clarify otherwise ambiguous situations; should rarely be used!</li>
                </ul>
                <p className="my-0">As a general rule, text that is common to a series of multiple questions is Explicit Context, the unique part of each question is Question Text, and Implicit Context shouldn't have to be used except in extremely unusual situations.</p>
                
                <div>
                    
                    {/* Explicit Context */}
                    <TextInput 
                        name="explicit"
                        value={displayTextField(explicit)}
                        onChange={updateTextField(setExplicit)}
                        label="Explicit Context"
                        textarea
                    /> 

                    {/* Question */}
                    <TextInput
                        name="question"
                        value={displayTextField(question)}
                        onChange={updateTextField(setQuestion)}
                        label="Question Text"
                        textarea
                    /> 

                    {/* Implicit Context */}
                    <TextInput
                        name="implicit"
                        value={displayTextField(implicit)}
                        onChange={updateTextField(setImplicit)}
                        label="Implicit Context"
                    /> 
                    
                    <div className="table-scroll mt-4">
                        <div className="table-scroll__wrapper">
                            <table className="w-100 table valuemapping-table">
        
                                {/* Table Header */}
                                <thead>
                                    <tr>
                                        <th>Encoded Value</th>
                                        <th>Answer Meaning</th>
                                    </tr>
                                </thead>
        
                                {/* Table Body */}
                                <tbody>
                                
                                    { variable.possible_values.map((mapkey, i) => (
                                        <tr key={i}>
                                            <td style={{verticalAlign: "middle", borderRight: "0px solid transparent"}}>
                                                <div className="d-flex">
                                                    {mapkey}
                                                </div>
                                            </td>
                                            <td style={{verticalAlign: "middle", borderRight: "0px solid transparent"}}>
                                                {mapkey !== "[UNK]" ?
                                                <TextInput
                                                    name={mapkey}
                                                    value={mappings[mapkey]}
                                                    label={mapkey + "-meaning"}
                                                    hideLabel
                                                  onChange={handleMappingUpdate}
                                                /> :
                                                <div className="d-flex">
                                                    {mappings[mapkey]}
                                                </div>}
                                            </td>
                                        </tr>
                                    ))}
                                
                                </tbody>
    
                                </table>
                        </div>
                    </div>
                    
                    {/* Submitter's Comment */}
                    <TextInput
                        name="comment"
                        value={comment}
                        onChange={setComment}
                        label="Submitter's Comments / Rationale For Change(s)"
                        textarea
                    /> 

                </div>

                { (onReset || onSubmit) &&

                    <div className="d-flex mt-4 justify-content-end align-items-center">

                        { onReset &&
                            <Button
                                size="xs"
                                variant="primary"
                                classes="font-weight-600"
                                uppercase
                                type="reset"
                            >
                                Cancel
                            </Button>
                        }

                        {onReset && onSubmit &&
                            <span className="p-1"></span>
                        }

                        { onSubmit &&

                            <Button 
                                size="md" 
                                variant="secondary"
                                classes="font-weight-600"
                                uppercase
                                type="submit"
                            >
                                Submit For Review <Icon name="check-circle" />
                            </Button>
                        }

                    </div>
                }

            </form>

        </section>
    )
}


/*VariableFieldEditForm.propTypes = {
    variable: PropTypes.shape(variableShape),
    hide: PropTypes.bool,
    onReset: PropTypes.func,
    onSubmit: PropTypes.func,
    setExplicit: PropTypes.func,
    setQuestion: PropTypes.func,
    setImplicit: PropTypes.func,
    setMappings: PropTypes.func,
}
*/
