//import PropTypes from "prop-types"
import { LoadingWrapper } from "../components/GeneralUI"
import { usePageTitle } from "../hooks"
import { condClass } from "../utils/conditionalClass"


export const InnerPage = ({
    // Buttons at the top right of screen
    actions,
    // children components wrapped by InnerPage layout
    children,
    // button node with handlers; used for pages that allow both view and edit of an entity
    editButton,
    // intro text
    intro,
    // boolean that displays loading spinner via LoadingWrapper if content is still loading
    loading,
    // whether or not this component's header should be sticky; used for pages with a primary action like "Save [entity]"
    sticky,
    // title text
    title
}) => {

    usePageTitle(title)

    return (
        <section className="inner-page">

            <div className={`inner-page__header ${condClass(sticky, "inner-page__header--sticky")}`}>

                {/*  Header (and "Edit" button) */}
                <div className="d-flex align-items-center">
                    <h2 className="h3">{title}</h2>
                    {editButton ? <div className="pl-2">{editButton}</div> : ''}
                </div>

                <div className="inner-page__actions">
                    {actions}
                </div>

            </div>
            
            {/* Helpers for the visual effects of the sticky header bar */}
            { sticky &&
                <>
                    <div className="inner-page__shadow"></div>
                    <div className="inner-page__cover"></div>
                </>
            }

            {/* Intro text */}
            { intro && 
                <p className="inner-page__intro">{intro}</p>
            }

            <LoadingWrapper loading={loading}>
                {/* Page content */}
                {children}
            </LoadingWrapper>
            
        </section>
    )
}

/*InnerPage.propTypes = {
    actions: PropTypes.node,
    children: PropTypes.node.isRequired,
    editButton: PropTypes.node,
    intro: PropTypes.string,
    loading: PropTypes.bool,
    sticky: PropTypes.bool,
    title: PropTypes.string.isRequired
}*/
