import { CustomVariables } from '../../components/ClinicalConstructs';
import { InnerPage } from '../../layout';
import { getPathFromRouteName } from "../../utils";
import { Button } from '../../components/Buttons';
import { Icon } from '../../components/GeneralUI';
import { NEW_DOWNCODE, NEW_TWO_VAR_CONSTRUCT } from '../../utils/constants';

const PageActions = () => (

    <div className="d-flex align-items-center">
        
        <Button variant="secondary" classes="position-static" to={getPathFromRouteName(NEW_DOWNCODE)}>
            New Downcode <Icon name="plus-circle"></Icon>
        </Button>
        <Button variant="secondary" classes="position-static" to={getPathFromRouteName(NEW_TWO_VAR_CONSTRUCT)}>
            New Combine <Icon name="plus-circle"></Icon>
        </Button>
    
    </div>

)

export const AllConstructs = () => {
    
    return (

        <InnerPage
            title="Custom Variables"
            intro="Custom Variables are a means of transforming existing variables in the database into forms more convenient for you and/or your study, by downcoding existing variables, or combining existing variables. Here you can review and edit any custom variables you may have already created, or create a new one."
            actions={<PageActions />}
        >

            <CustomVariables />

        </InnerPage>
    
    )
}
