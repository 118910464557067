import React, { useEffect, useState } from 'react';
//import PropTypes from 'prop-types';

import { Pagination } from "./";
import { TableWrapperContext } from '../../contexts/TableWrapperContext';


export const TableWrapper = ({ children, rows, setVisibleRows, tableId }) => {

    const tableWrapperContextValue = {
        id: tableId
    }

    const [ perPage, setPerPage ] = useState(10);
    
    // Actual page number, not 0-indexed
    const [ currentPage, setCurrentPage ] = useState(1); 

    const goToPage = (pageNum) => {
        let newCurrentPage = pageNum;
        if (typeof newCurrentPage === 'string') {
            newCurrentPage = parseInt(newCurrentPage)
        }
        /**
         * @todo 
         * make sure page is in range
         */
        setCurrentPage(newCurrentPage)
    }

    // reset back to page 1 if new data is rendered
    useEffect(() => {
        setCurrentPage(1)
    }, [rows])


    // update currentPage if old currentPage is smaller than new totalPages
    useEffect(() => {
        if (rows) {
            const totalPages =  Math.ceil(rows.length / perPage);

            if (currentPage > totalPages) {
                setCurrentPage(totalPages || 1)
            }
        }
    }, [ currentPage, perPage, rows ])

    // update the visible rows according to pagination choices
    useEffect(() => {
        if (rows && rows.length) {
            const pageLowerLimit = (currentPage - 1) * perPage;
            const pageUpperLimit = pageLowerLimit + perPage;
            setVisibleRows(rows.slice(pageLowerLimit, pageUpperLimit));
        }
    }, [currentPage, perPage, rows, setVisibleRows])

    return (
        <TableWrapperContext.Provider value={tableWrapperContextValue}>

            {children}

            <Pagination 
                tableId={tableId}
                perPage={perPage}
                setPerPage={setPerPage}
                rowCount={rows ? rows.length : 1}
                goToPage={goToPage}
                currentPage={currentPage}
            />

        </TableWrapperContext.Provider >
    )
}

/*TableWrapper.propTypes = {
    children: PropTypes.node,
    rows: PropTypes.arrayOf(PropTypes.object),
    setVisibleRows: PropTypes.func,
    tableId: PropTypes.string.isRequired,
}*/
