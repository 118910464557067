import { VARIABLE_TYPES } from "./variableTypes";

export const CONSTRAINT_TYPES = {
    EXISTS: {
        label: "exists; AVOID USING THIS",
        valCount: 0
        },
    VALID: {
        label: "has a valid answer",
        valCount: 0
        },
    REGEX: {
        label: "contains",
        valProp: "constraint_value",
        valCount: 1,
        regex: "value"
    },
    NREGEX: {
        label: "does not contain",
        valProp: "constraint_value",
        valCount: 1,
        nregex: "value"
    },
    STARTS: {
        label: "starts with",
        valProp: "constraint_value",
        valCount: 1,
        regex: "^value"
    },
    ENDS: {
        label: "ends with",
        valProp: "constraint_value",
        valCount: 1,
        regex: "value$"
    },
    EQ: {
        label: "is equal to",
        valProp: "constraint_value",
        valCount: 1
    },
    NEQ: {
        label: "is not equal to",
        valProp: "constraint_value",
        valCount: 1
    },
    GTE: {
        label: "is greater than or equal to",
        valProp: "constraint_value",
        valCount: 1
    },
    LTE: {
        label: "is less than or equal to",
        valProp: "constraint_value",
        valCount: 1
    },
    GT: {
        label: "is greater than",
        valProp: "constraint_value",
        valCount: 1
    },
    LT: {
        label: "is less than",
        valProp: "constraint_value",
        valCount: 1
    },
    BETWEEN: {
        label: "is between",
        valProp: "constraint_values",
        valCount: 2
    },
    // Removing ANYOF because of input confusion problems; we'll bring it back
    // when we have a better way.
    /* ANYOF: {
        label: "is any of",
        valProp: "constraint_values",
        valCount: -1,
        helpText: "Enclose comma-separated quoted values in square brackets. For example: [\"my, value\", \"your, value\"].",
    },*/
}


// All can be "Unknown" or undefined in addition to unique values
// NOTE: "EXISTS" and "VALID" hypothetically could apply to *all* variable
// types, but in testing they proved to cause user confusion, so nowadays
// EXISTS is only used for backend wrangling purposes and VALID is only used
// for dichotomous variables.
// NOTE 2: The `variable_type + "_PED"` constraint categories exist because
// Ascertain Pedigrees is limited in what constraint types it can actually
// successfully implement at this time.
export const VARIABLE_TYPE_CONSTRAINTS = {
    // integer, but one that maps to a string
    // FIXME: consider bringing back "VALID" at the end of the list, since it
    // (well, EXISTS, but close enough) had legit applications in multivalue
    // customvars)
    [VARIABLE_TYPES.CATEGORICAL]: [ "EQ", "NEQ" ],
    [VARIABLE_TYPES.CATEGORICAL + "_PED"]: ["EQ",],
    // boolean
    [VARIABLE_TYPES.DICHOTOMOUS]: [ "VALID" ],
    [VARIABLE_TYPES.DICHOTOMOUS + "_PED"]: ["VALID",],
    // YYYYMMDD. U can be substituted for unknown portion of date. (Or "Unknown")
    [VARIABLE_TYPES.DATE]: [ /* "ANYOF", */ "EQ", "NEQ", "GT", "LT", "GTE", "LTE", "BETWEEN" ],
    // like categorical, but can pick more than one
    [VARIABLE_TYPES.MULTICATEGORY]: [ /* "ANYOF", */ "EQ", "NEQ" ],
    // Integer (or "Unknown")
    [VARIABLE_TYPES.NUMBERRANGE]: [ /* "ANYOF", */ "EQ", "NEQ", "GT", "LT", "GTE", "LTE", "BETWEEN" ],
    // Explicit identifying datum; otherwise same as "text"
    [VARIABLE_TYPES.IDENTIFIER]: [ /* "ANYOF", */ "EQ", "REGEX", "NREGEX", "STARTS", "ENDS" ],
    // Free-form, of any length
    [VARIABLE_TYPES.TEXT]: [ /* "ANYOF", */ "EQ", "REGEX", "NREGEX", "STARTS", "ENDS" ],
    // Type not yet defined in database; treat as Text
    [VARIABLE_TYPES.NULL]: [ /* "ANYOF", */ "EQ", "REGEX", "NREGEX", "STARTS", "ENDS" ],
}
