//import PropTypes from "prop-types";

import { Count } from "../Typography";
import { IconButton } from "../Buttons"
import { VariableDefaultActions } from './'
import { variableFieldDisplay } from '../../utils'

/**
 * Component for displaying a variable outside of a table, i.e. in VariablesCollectionForm
 * Includes name, question and actions.
 */
export const Variable = ({actions = [], variable}) => {

    return (
        <div className="variable">
            
            <div className="variable__bg"></div>
            
            {/* Name (and count) */}
            <div className="variable__content">
                <div className="variable__name">
                    {variable.variable_name} <Count>{variable.observations}</Count>
                </div>

                {/* Question */}
                <div className="variable__question">
                    {variableFieldDisplay.fullquestion.xform(variable)}
                </div>

                {/* Actions */}
                <div className="icon-btn-group ml-auto">

                    <VariableDefaultActions variable={variable} />

                    {
                        actions.map((action, i) => {
                            let to = '';
                            let onClick = null;

                            if (typeof action === 'function') {
                                action = action(variable);
                            }

                            if (action.to) {
                                to = action.to.replace(':id', 1)
                            } else if (action.onClick) {
                                onClick = () => action.onClick(variable)
                            }
                            
                            return <IconButton 
                                key={i} 
                                {...action} 
                                to={to} 
                                onClick={onClick} 
                                variant={action.variant ? action.variant : "outline"}
                            />
                        })
                    }

                </div>
            </div>

        </div>
    )
}

/*Variable.propTypes = {
    actions: PropTypes.arrayOf(PropTypes.object),
    variable: PropTypes.object,
}*/
