import { getCohortType } from ".";
import { COHORT_TYPES } from "./constants";


// recursively loop through children and add their children to the parent
const traverse = (cohort) => {
    const subsets = [];

    if (cohort.children) {

        cohort.children.forEach(child => {
            subsets.push(child)
            if (child.children) {
                subsets.push(traverse(child.children));
            }
    
        })
    }

    return subsets;
}

const getCohortDictionary = (cohorts) => {
    const mainDict = {};

    // make dictionary of cohorts by name
    cohorts.forEach(cohort => {
        mainDict[cohort.cohortid] = cohort;
    })

    return mainDict;

}

const parseCohortOfInterestTree = (cohorts, cohortOfInterest) => {

    const mainDict = getCohortDictionary(cohorts);

    const cohortType = getCohortType(cohortOfInterest);

    const cohort = mainDict[cohortOfInterest.cohortid];

    if (cohortType === COHORT_TYPES.COMPOUND) {   

        const firstParentId = cohort.parent_id;
        const secondParentId = cohort.secondparent_id;

        cohort.parents = [ 
            mainDict[firstParentId], 
            mainDict[secondParentId] 
        ];

        return cohort;
    } 
    else if (cohortType === COHORT_TYPES.PEDIGREES) {

        cohort.parents = [ mainDict[cohort.parent_id] ];
        
        return cohort;
    
    } 
    else if (cohort.parent_id !== null) {
        const parent = mainDict[cohort.parent_id];

        parent.children = [ cohort ];

        return parseCohortOfInterestTree(cohorts, parent);
    } 
    else {
        return cohortOfInterest;
    }
}

const parseCohortListToTree = (cohorts) => {
    let root; // the root of all child sets
    let compoundSets = [];
    let pedigreeSets = [];

    const mainDict = getCohortDictionary(cohorts);

    // sort children to their parent in dictionary
    cohorts.forEach(cohort => {

        if (cohort.cohortid === 1)  {
            
            root = cohort;

        } else if (cohort.parent_id) {

            const parentElement = mainDict[cohort.parent_id];

            if (!parentElement) {
                console.error(`Parent element with id ${cohort.parent_id} not found for cohort ${cohort.cohortid}`);
                return;
            }
            
            const cohortType = getCohortType(cohort);
            
            // Sort to compound sets
            if (cohortType === COHORT_TYPES.COMPOUND) {
                
                if (!cohort.parents)  {
                    cohort.parents = [];
                }

                // Get this cohort's parents
                const firstParentId = cohort.parent_id;
                const secondParentId = cohort.secondparent_id;
                
                // Make a copy of the parents, so that we can delete their children (for display purposes)
                const firstParent = {...mainDict[firstParentId]}
                const secondParent = {...mainDict[secondParentId]}

                // Delete parents' children and parents so they don't render in this context
                delete firstParent.children
                delete secondParent.children
                delete firstParent.parents
                delete secondParent.parents

                // Add newly copied/massaged parents to cohort
                cohort.parents.push(firstParent)
                cohort.parents.push(secondParent)

                compoundSets.push(cohort)

            } 
            // sort to pedigree sets
            else if (cohortType === COHORT_TYPES.PEDIGREES) {

                if (!cohort.parents)  {
                    cohort.parents = [];
                }
                
                // Make a copy of parent, so that we can delete its children (for display purposes)
                const parent = {...parentElement}

                // Delete parent's children and parents so they don't render in this context
                delete parent.children
                delete parent.parents
                
                // Add newly copied/massaged parent to cohort
                cohort.parents.push(parent)

                pedigreeSets.push(cohort)
            
            } 
            // sort to subsets
            else if (cohortType === COHORT_TYPES.SUBSET) {
            
                if (!parentElement.children)  {
                    parentElement.children = [];
                }
                parentElement.children.push(cohort);
            } 
        
        }
    })
    
    // recursively loop through children and add their children to the parent, starting with root
    traverse(root);

    
    return {
        [COHORT_TYPES.SUBSET]: [root],
        [COHORT_TYPES.COMPOUND]: compoundSets,
        [COHORT_TYPES.PEDIGREES]: pedigreeSets
    };
}

export {parseCohortListToTree, parseCohortOfInterestTree}
