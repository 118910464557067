import { useRef, useEffect } from 'react';

/**
 * Keeps track of if this is the first render; helpful for 
 * useEffect functions that need to only run once, but must include 
 * changing variables in their dependency arrays
 **/
export const useIsFirstRender = () => {
  const isFirstRenderRef = useRef(true);
  useEffect(() => {
    isFirstRenderRef.current = false;
  }, []);
  return isFirstRenderRef.current;
};