import { useCallback, useState } from 'react';

import { TextInput } from '../FormElements'
import api from '../../utils/api'
import { DeleteButton } from '../Buttons';

export const DeleteConstructButton = ({ construct, returnRoute }) => {

    const [ name, setName ] = useState("")

    const handleNameChange = useCallback((val) => setName(val), [])

    return (
        <DeleteButton
            id={construct.id || construct.variable_lookup_id}
            entityLabel="Custom Variable"
            deleteFn={api.deleteConstruct}
            returnRoute={returnRoute}
            modal={{
                title: "Delete Custom Variable?",
                button: {
                    disabled: name !== construct.variable_name
                },
                body: (
                    <>
                        <p>Deleting this Custom Variable will also delete any Cohorts, Variable Sets, or other Custom Variables it in included in.</p>
                        
                        <p>Please confirm that you’d like to delete <strong>{construct.variable_name}</strong> by typing the Custom Variable name <strong>{construct.variable_name}</strong> below.</p>

                        <TextInput
                            required
                            label="Custom Variable Name"
                            name="delete-confirm-name"
                            value={name}
                            onChange={handleNameChange}
                        />
                        
                        <p>Note that in some cases deletion can take several minutes. Please be patient.</p>
                    </>
                ),
            }}
        />
    )
}
