import { useState } from "react";
//import PropTypes from "prop-types";

import { RadioInput } from "./RadioInput";

let count = 0;

export const RadioGroup = ({ legend, options }) => {

    // Generates automatic unique name for group
    const [ name ] = useState(('radioGroup-' + count++));

    const [ selectedOption, setSelectedOption ] = useState(null);

    const handleChange = v => setSelectedOption(v)

    return (
        <fieldset>

            { 
                legend && 

                <legend>
                    {legend}
                </legend>
            }

            {
                options.map((opt, i) => (
                    <RadioInput
                        key={i}
                        name={name}
                        onChange={handleChange}
                        selectedOption={selectedOption}
                        value={opt.value}
                        label={opt.label}
                    />
                ))
            }


        </fieldset>

    )
}

/*RadioGroup.propTypes = {
    legend: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
    }))
}*/
