import { LinkedCard, Spacer } from '../components/GeneralUI';
import { ParagraphLeadIn } from '../components/Typography/ParagraphLeadIn';
import { usePageTitle } from '../hooks';
import { getPathFromRouteName } from '../utils';
import { ALL_VARIABLES, ALL_COHORTS, ASCERTAIN_PEDIGREE, ALL_CLINICAL_CONSTRUCTS, NEW_DOWNLOAD, MY_VARIABLE_SETS } from '../utils/constants';


export const Home = () => {

    //FIXME: The counts in Variables Catalog and Build a Cohort are info that
    // can be pulled from the DB; do we want to do this automatically?
    const menuItems = [
        {
            to: getPathFromRouteName(ALL_VARIABLES),
            title: "Explore the Variables Catalog",
            text: "See and search through all variables in the database, covering 38 distinct NIMH diagnostic interview forms from 57 studies across 8 clinical collections, diagnoses and demographics, and information on availablility of DNA and/or genotypes."
        },
        {
            to: getPathFromRouteName(ALL_CLINICAL_CONSTRUCTS),
            title: "Create a Custom Variable",
            text: "Transform our existing variables into a form that's more convenient for you, by downcoding or combining existing variables."
        },
        {
            to: getPathFromRouteName(ALL_COHORTS),
            title: "Build a Cohort",
            text: "Assemble sets of individuals based on variables of interest to your study from the 133,372 individuals in the database."
        },
        {
            to: getPathFromRouteName(ASCERTAIN_PEDIGREE, { id: "" }),
            title: "Ascertain Pedigrees",
            text: "Expand a Cohort by finding related individuals."
        },
        {
            to: getPathFromRouteName(MY_VARIABLE_SETS),
            title: "Assemble a Variable Set",
            text: "Create a list of variables that contain information of interest to you and/or your study, or review any existing such lists you may have already created."
        },
        {
            to: getPathFromRouteName(NEW_DOWNLOAD),
            title: "Download Data",
            text: "Download information in one of your Variable Sets for one of your Cohorts."
        }
    ];

    usePageTitle("DIVER Dashboard")

    return (
        <div>

            <p>
                Welcome to the in-development version of the NRGR DIVER (Diagnostic Interview Variable Exploration & Retrieval) webapp. Please note that there are still likely to be bugs and/or missing functionality. Things known to be nonfunctional or nonexistent at this time include but are not limited to editing of Custom Variables and pedigree drawing. User-visible changes are <a href="https://app.simplenote.com/p/K4PTYW">published as we deploy them</a>. Should you encounter errors elsewhere, please notify <a href="mailto:jvc@mathmed.org">jvc@mathmed.org</a> or <a href="https://github.com/MathematicalMedicine/diverweb-issues/issues">report an issue on GitHub</a>.
            </p>
            
            <ParagraphLeadIn>
            DIVER is a tool for exploring, extending, and retrieving phenotypic information on the tens of thousands of individuals and families who completed diagnostic interviews as a part of NIMH-funded genetic studies over the last four decades. The diagnostic instruments range from short, 60-question topical surveys to detailed, ~2000-question interviews covering a broad variety of information relevant to	psychological disorders. Tissue samples and genotypes are available for the majority of individuals who completed interviews.
            </ParagraphLeadIn>
            
            <Spacer size={3} />
            
            <div className="inner-page__header">
            <div className="d-flex align-items-center">
            <h2 className="h3">Dashboard</h2>
            </div></div>

            <div className="row">

                {menuItems.map((item, i) => (

                    <div key={i} className="col-12 col-sm-6 col-md-4 py-3 d-flex">
                        <LinkedCard {...item} />
                    </div>

                ))}

            </div>

        </div>
    )
}
