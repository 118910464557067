import { useCallback } from "react";
//import PropTypes from "prop-types";

import { ConstraintInput, SelectConstraintType } from ".";
//import { variableShape } from "../../utils";

export const ConstraintForm = ({ 
    index,
    constraintType, 
    gutterSize = 3,
    removeButton,
    setConstraintType: _setConstraintType, 
    setConstraintValue: _setConstraintValue,
    variable,
    isPedigreeForm,
}) => {



    // set constraint value in parent
    const setConstraintValue = useCallback((value) => (
        _setConstraintValue(value, index)
    ), [index, _setConstraintValue])

    // set constraint type in parent
    const setConstraintType = useCallback((value) => (
        _setConstraintType(value, index)
    ), [index, _setConstraintType])

    return (
        <>
            <div className={`col-12 col-sm-6 col-md-3 px-${gutterSize}`}>
                
                {/* Select dropdown for constraint type, i.e. "exists", "is valid"... */}
                <SelectConstraintType 
                    constraintType={constraintType}
                    dataType={variable.data_type}
                    setConstraintType={setConstraintType}
                    isPedigreeForm={isPedigreeForm}
                />

            </div>

            <div className={`col-12 col-sm-6 d-flex align-items-center px-${gutterSize} position-relative`}>
                
                {/* Input if constraint type requires value, i.e. "greater than ____"  */}
                <ConstraintInput
                    variable={variable}
                    setConstraintValue={setConstraintValue}
                    constraintType={constraintType}
                />

                {/* If this form can be removed, include button passed from parent */}
                {removeButton ? removeButton : ''}

            </div>

        </>
    )
}

/*ConstraintForm.propTypes = {
    index: PropTypes.number,
    constraintType: PropTypes.string.isRequired, 
    gutterSize: PropTypes.number,
    removeButton: PropTypes.node,
    setConstraintType: PropTypes.func.isRequired, 
    setConstraintValue: PropTypes.func.isRequired,
    variable: PropTypes.shape(variableShape),
    isPedigreeForm: PropTypes.bool,
}*/
