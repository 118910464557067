import { useState } from "react";
//import PropTypes from "prop-types";

import { condClass } from "../../utils/conditionalClass";

let count = 0;

export const NumberInput = ({ classes, helpText, hideLabel, label, name, onChange, value, ...rest}) => {

    // Generates automatic unique id
    const [ id ] = useState(('numberInput-' + count++));

    const handleChange = (e) => {
        const val = e.target.value;
        // There's no "onChange" for a number input (at least in FF)
        // so we use a text input instead and just check here for numbers     
        if (val.match(/^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/)) {
            onChange(val, e.target.name)
        } else if (val === '' || val ==='.' || val === '-') {
            onChange(val, e.target.name)
        }
    }

    return (
        <>

            {!hideLabel && label && 
                <label htmlFor={id}>
                    {label}
                </label>
            }

            <input 
                id={id}
                 // There's no "onChange" for a number input (at least in FF)
                // so we use a text input instead and just check in onChange for numbers
                type="text"
                aria-label={hideLabel ? label : false}
                name={name}
                className={`form-control number-input${condClass(classes, classes)}`}
                onChange={handleChange}
                value={value}
                aria-describedby={`${helpText ? id + "-help " : ""}` || null}
                {...rest}
            />

            {/* Help text */}
            {helpText &&
                <small id={`${id}-help`} className="form-text show">{helpText}</small>
            }


        </>
    )
}

/*NumberInput.propTypes = {
    classes: PropTypes.string,
    helpText: PropTypes.string,
    hideLabel: PropTypes.bool,
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]) 
}*/
