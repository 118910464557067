//import PropTypes from "prop-types"

/**
 * Adds a wrapper component based on a condition. 
 * If condition is not present, either no wrapper 
 * is added or the "alt" conditional wrapper is added
 **/
export const ConditionalWrapper = ({ altWrapper, children, condition, wrapper }) => (
    condition ? wrapper(children) : altWrapper ? altWrapper(children) : children
)

/*ConditionalWrapper.propTypes = {
    altWrapper: PropTypes.func,
    children: PropTypes.node.isRequired,
    condition: PropTypes.bool,
    wrapper: PropTypes.func,
}*/
