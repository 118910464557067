import { CONSTRAINT_TYPES } from "./constants/constraints";

const replaceAtIndex = (string, index, replacement) => {
    return string.substr(0, index) + replacement + string.substr(index + 1, string.length - index)
}

/**
 * Gets the "plain" language description of the constraints 
 * for a cohort constrained by a variable, i.e. a Subset Cohort
 */
export const getPlainLangVariableConstraint = (constraintType, constraintValues) => {
    let text = CONSTRAINT_TYPES[constraintType].label + ' ' || '';

    if (constraintType === 'BETWEEN') {
        return `${text}${constraintValues[0]} and ${constraintValues[1]}`;
    }
    if (constraintType === 'ANYOF') {
        const lastValue = constraintValues.pop();
        let valuesString = constraintValues.join(', ');
        valuesString += `, or ${lastValue}`;
        return text + valuesString;
    }
    if (constraintValues !== null && typeof constraintValues !== "undefined") {
        return text + constraintValues;
    } else {
        return text;
    }
}

/**
 * Gets the "plain" language description of the constraints 
 * for a cohort constrained by its relationship to another cohort, i.e. a Compound Cohort
 */
export const getPlainLangRelationConstraint = (constraintType, constraintValues) => {
    if (!constraintType || !constraintValues) return '';

    let text = replaceAtIndex(constraintType, 0, constraintValues[0])
    text = replaceAtIndex(text, text.length - 1, constraintValues[1])

    return text;
}

export const getPlainLangCompoundRelationship = (relationship) => {
    switch (relationship) {
        case "intersect":
        case "INTERSECT":
            return "Intersection";
        case "subtract":
        case "SUBTRACT":
            return "Subtraction";
        case "union":
        case "UNION":
            return "Union";
        default:
            return "Unknown Compound Relationship"
    }
}


const getQualifier = (qualifier) => {
    switch(qualifier) {
        case ">=": 
            return "at least";
        case "<=":
            return "at most";
        case "=":
            return "exactly";
        default: 
            return "unknown"; // or return some kind of error message?
    }
}


/**
 * Gets the "plain" language description of the constraints 
 * for a cohort constrained by pedigree, i.e. a Pedigree Set
 */
export const getPlainLangPedigreeConstraint = (varname, constraintValues) => {
    
    const [ varval, minpedsize, affconstraint, affecteds ] = constraintValues;
    
    if (varname != null) {
        return `Include relatives of individuals in this index cohort with at least ${minpedsize} family members and for which ${varname} is equal to ${varval} for ${getQualifier(affconstraint)} ${affecteds} family members`;
    } else if (minpedsize != null) {
        return `Include relatives of individuals in this index cohort with at least ${minpedsize} family members`;
    } else {
        return "Include all relatives of individuals in this index cohort";
    }
}
