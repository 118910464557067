//import PropTypes from "prop-types";

import Icons from "../../images/icons/iconsSprite.svg"

/**
 * SVG for displaying the custom icons from the icon sprite
 */
export const SvgIcon = ({ name, size }) => (
  <svg className={`icon icon-${name}`} fill={"currentColor"} width={size} height={size}>
    <use xlinkHref={`${Icons}#icon-${name}`} />
  </svg>
);

/*SvgIcon.propTypes = {
  name: PropTypes.oneOf([
    "combine-cohort",
    "expanded-detail",
    "explore-harmonization",
    "pedigree",
    "relation-union",
    "relation-intersect",
    "relation-subtract",
  ]),
  size: PropTypes.number,
}*/
