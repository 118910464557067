//import PropTypes from "prop-types";
import { useState } from "react";
import { Link } from "react-router-dom";

import { /*cohortShape, */getPathFromRouteName } from "../../utils";
import { VIEW_COHORT } from "../../utils/constants";
import { Tooltip } from "../GeneralUI";

export const CohortNameLink = ({ cohort }) => {

    const [ showTooltip, setShowTooltip ] = useState(false);

    const toggleTooltipOn = () =>  {
        setShowTooltip(true);
    }
    
    const toggleTooltipOff = () => {
        setShowTooltip(false);
    }
    
    return (
        <span className="position-relative">
            <Link
                to={getPathFromRouteName(VIEW_COHORT, { id: cohort.cohortid })}
                target="_blank"
                onFocus={toggleTooltipOn}
                onBlur={toggleTooltipOff}
                onMouseEnter={toggleTooltipOn}
                onMouseLeave={toggleTooltipOff}
            >
                {cohort.name}
            </Link>
            
            <Tooltip show={showTooltip}>
                View Details <i className="pl-1 fas fa-external-link-alt"></i>
            </Tooltip>
        </span>
    )

}

/*CohortNameLink.propTypes = {
    cohort: PropTypes.shape(cohortShape), 
}*/
