import { useEffect, useState } from "react"
import { useClinicalConstructContext } from "../../contexts";
import { usePrevious } from "../../hooks";
import { VARIABLE_TYPE_CONSTRAINTS } from "../../utils/constants";
import { IconButton } from "../Buttons";
import { ConstraintInput, SelectConstraintType } from "../Constraints";
import { Select } from "../FormElements";

export const DowncodeRow = ({ index, isDefault, selectedValue, setSelectedValue, setConstraint, removeRow }) => {

    const [ constraintType, setConstraintType ] = useState();
    const [ constraintValue, setConstraintValue ] = useState();

    const prevConstraintType = usePrevious(constraintType)
    const prevConstraintValue = usePrevious(constraintValue)

    // Context values
    const { 
        variables,
        values,
        defaultValue,
        setDefaultValue
    } = useClinicalConstructContext();

    const variable = variables[0]

    // Set constraint type to the default for this variable type
    useEffect(() => {
        if (variables[0] && variables[0].data_type) {
            setConstraintType(VARIABLE_TYPE_CONSTRAINTS[variables[0].data_type][0])
        } else {
	    alert('not trying!')
	}
    }, [variables])


    
    useEffect( () => {

        if (isDefault) {
            return;
        }

        // don't run again if these values aren't new
        if (prevConstraintType === constraintType && prevConstraintValue === constraintValue) {
            return;
        }

        const constraint = {
            variable_name: variable.variable_name,
            constraint_type: constraintType,
            constraint_value: constraintValue,
        };

        setConstraint(constraint, index);

    }, [
        constraintType,
        constraintValue,
        index,
        isDefault,
        prevConstraintType,
        prevConstraintValue,
        selectedValue,
        setConstraint,
        variable
    ])


    const handleValueChange = (v) => {
        if (isDefault) {
            setDefaultValue(v)
        } else {
            setSelectedValue(v, index);
        }
    }

    if (!constraintType) {
        return <></>;
    }
    
    return (
        <tr>

            {/* Constraint Column */}
            <td style={{verticalAlign: "middle", borderRight: "0px solid transparent"}}>
                <div className="d-flex">
                    
                    {isDefault ? 

                        <div className="ml-auto">
                            Otherwise use default value:
                        </div> 
                        
                    : 
                        <>
                                
                            <SelectConstraintType
                                constraintType={constraintType}
                                dataType={variable.data_type}
                                setConstraintType={setConstraintType}
                            />


                            <div className="ml-3 d-flex align-items-center">
                                
                                <ConstraintInput 
                                    constraintType={constraintType}
                                    setConstraintValue={setConstraintValue}
                                    variable={variable}
                                />

                            </div>

                        </>
                    
                    }

                </div>
            </td>

            {/* Values Column */}
            <td style={{borderRight: "0px solid transparent"}}>
                
                {
                    values.length < 2 ? 
                        <div>You must create at least two values using the form above.</div>
                    :

                        <Select
                            label={`${isDefault ? "Default" : "" } Value`}
                            hideLabel
                            name={"value-" + index}
                            options={[
                                {name: "--", value: ""},
                                ...values.filter(v=>v).map(( v, i ) => ({
                                    name: v.name,
                                    //value: i,
                                    value: v.name,
                                }))
                            ]}
                            value={isDefault ? defaultValue ? defaultValue : "" : selectedValue}
                            onChange={handleValueChange}
                        />
                }
               
            </td>
            
            { removeRow &&
                <td style={{width: 75, borderRight: "0px solid transparent"}}>
                    <div className="d-flex align-items-center justify-content-center">
                        <IconButton
                            variant="info"
                            onClick={removeRow}
                            aria-label="Delete Row"
                            tooltip="Delete row"
                            iconName="trash-alt"
                        />
                    </div>
                </td>
            }
                
        </tr>
    )
}
