//import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { toast } from "react-toastify";

import { useAppState/*, useCancelAsyncCall*/ } from "../../hooks";
import api from '../../utils/api';
import { LoadingWrapper } from '../GeneralUI';


export const ConstructMappingTableReadonly = ({construct}) => {
    
    //const cancelAsyncCall = useCancelAsyncCall();
    const { errorCheckText } = useAppState();
    const [ variables, setVariables ] = useState([]);
    const [ loading, setLoading ] = useState(true);

    // Get extended info on contributing vars
    useEffect(() => {
        if (!construct || !construct.contributors) return;
        const getVariables = construct.contributors.map(v => api.getVariableByName(v));
        Promise.all(getVariables)
            .then(res => {
                //if (cancelAsyncCall()) return null;
                
                setVariables(res.map(r => r.data[0]));
            })
            .catch(err => {
                toast.error(errorCheckText(err, 'Unable to get the variables included in this Custom Variable.'))
            })
            .finally(() => setLoading(false))

    }, [/*cancelAsyncCall, */construct, setVariables, setLoading, errorCheckText])


    const getCellValue = (var0Val, var1Val, returnidx) => {
        let foundValue = "";

        construct.value_definitions.forEach(val => { 
            val.definition.constraints.forEach(constraintL1 => {
                if (constraintL1.constraints[0].constraint_value === var0Val 
                    && constraintL1.constraints[1].constraint_value === var1Val
                ) {
                    if (returnidx) {
                        foundValue = construct.possible_values.indexOf(val);
                    } else {
                        foundValue = val.name;
                    }
                    return;
                }
            })
        })

        return foundValue;
    }

    if (!construct) {
        return <></>;
    }
    
    if (!variables[0] || !variables[0].possible_values) {
        return <></>;
    }


    return (
        <LoadingWrapper loading={loading} smallText>
            <table className="embed-table construct-map-table">
                
                <thead>

                    <tr>
                        <th className="table__blank-cell table__blank-cell--has-sibling"></th>
                        <th className="table__blank-cell"></th>

                        {/* First variable name header */}
                        <th colSpan={variables[0].value_mapping.length} className="span-header span-header--col">
                            {variables[0].variable_name}
                        </th>
                    </tr>
                    
                    {/* First variable values header */}
                    <tr>
                        <th className="table__blank-cell table__blank-cell--has-sibling"></th>
                        <th className="table__blank-cell"></th>

                        
                        {
                            variables[0].value_mapping.map((val, i) => (
                                <th key={i}>
                                    {val}
                                </th>
                            ))
                        }
                    </tr>

                </thead>

                <tbody>
            
                    {
                        variables[1].value_mapping.map((val1, i) => (
                            <tr key={i}>

                                {/* Second variable name "header" */}
                                {i === 0 &&
                                    <th rowSpan={variables[1].value_mapping.length} className="span-header span-header--row">
                                        <div>{variables[1].variable_name}</div>
                                    </th>
                                }

                                {/* Second variable values "header" (done one at a time since we're iterating over second variable values) */}
                                <th>
                                    {val1}
                                </th>
                            
                                {
                                    variables[0].value_mapping.map((val0, j) => (
                                        <td key={j}>

                                            { getCellValue(variables[0].possible_values[j],  variables[1].possible_values[i]) }

                                        </td>
                                    ))
                                }

                            </tr>
                        ))
                    }

                </tbody>
            </table>
        </LoadingWrapper>
    )

}

/*ConstructMappingTableReadonly.propTypes = {
    construct: PropTypes.object,
}*/
