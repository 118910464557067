import { useEffect, useState } from "react";
//import PropTypes from "prop-types";

import { PageLink } from "./";
import { Select } from "../FormElements/Select";
import { Icon } from "../GeneralUI/Icon";
import { useTableWrapperContext } from "../../contexts/TableWrapperContext";
import { condClass } from "../../utils/conditionalClass";

const getPerPageOptions = (values) => (
    values.map(value => ({
        name: value + ' Per Page',
        value: value.toString()
    }))
)

const getPageSelectOptions = (totalPages) => {
    const pages = [{
        name: "Jump to Page",
        value: "1",
    }];

    for (let i = 1; i <= totalPages; i++) { 
        pages.push(
            {
                name: i.toString(),
                value: i.toString()
            }
        )
    }

    return pages;
}


export const Pagination = ({ currentPage, goToPage, perPage, rowCount, setPerPage }) => {

    const [ totalPages, setTotalPages ] = useState(Math.ceil(rowCount / perPage));

    const { id } = useTableWrapperContext();
    
    useEffect(() => {
        const pageCount = Math.ceil(rowCount / perPage)
        setTotalPages( Math.max(1, pageCount )) // "No results" is still 1 page 
    }, [ rowCount, perPage])

    useEffect(() => {
        if (perPage <= 0) {
            return;
        }

        // show current page
        // show 1 # on either side of current page
        // if first and last not included, 

    }, [perPage])


    return (

        <div className="d-flex table-controls justify-content-between align-items-start flex-wrap mb-3">
            <div className="p-1">

                <div className="" id={`${id}_paginate`}>
                    <ul className="pagination">

                        {/* First */}
                        <PageLink 
                            classes={condClass(currentPage === 1, 'disabled')}
                            goToPage={() => goToPage(1)}
                            variant="secondary"
                        >
                            <Icon name="angle-double-left" />
                            <span className="sr-only">First</span>
                        </PageLink>

                        {/* Previous */}
                        <PageLink 
                            classes={condClass(currentPage === 1, 'disabled')}
                            goToPage={() => goToPage(currentPage - 1)}
                            variant="secondary"
                        >
                            <Icon name="angle-left" />
                            <span className="sr-only">Previous</span>
                        </PageLink>


                        { currentPage - 2 > 0 &&
                            <>
                                <PageLink 
                                    goToPage={() => goToPage(1)} 
                                    noBtnClass
                                >
                                    1
                                </PageLink>

                                { currentPage - 2 > 1 &&
                                    <PageLink>…</PageLink>
                                }
                            </>
                        }

                        { currentPage - 1 > 0 &&
                            <PageLink 
                                goToPage={() => goToPage(currentPage - 1)} 
                                noBtnClass
                            >
                                {currentPage - 1}
                            </PageLink>
                        }

                        {/* Current Page */}
                        <PageLink 
                            goToPage={() => goToPage(currentPage)} 
                            active
                            noBtnClass
                        >
                            {currentPage}
                        </PageLink>

                        
                        {currentPage + 1 < totalPages &&
                            <PageLink 
                                goToPage={() => goToPage(currentPage + 1)} 
                                noBtnClass
                            >
                                {currentPage + 1}
                            </PageLink>
                        }

                        {currentPage + 1 <= totalPages &&
                            <>
                                { currentPage + 2 < totalPages &&
                                    <PageLink 
                                        noBtnClass
                                    >…</PageLink>
                                }

                                <PageLink 
                                    goToPage={() => goToPage(totalPages)} 
                                    noBtnClass
                                >
                                    {totalPages}
                                </PageLink>
                            </>
                        }

                        {/* Next */}
                        <PageLink 
                            classes={condClass(currentPage === totalPages, 'disabled')}
                            goToPage={() => goToPage(currentPage + 1)}
                            variant="secondary"
                        >
                            <Icon name="angle-right" />
                            <span className="sr-only">Next</span>
                        </PageLink>

                        <PageLink 
                            classes={condClass(currentPage === totalPages, 'disabled')}
                            goToPage={() => goToPage(totalPages)}
                            variant="secondary"
                        >
                            <Icon name="angle-double-right" />
                            <span className="sr-only">Last</span>
                        </PageLink>

                    </ul>
                </div>
            </div>
            
            <div className="d-flex">

                <div className="p-1">
                    <div>
                        
                        <Select
                            label="Records per page"
                            hideLabel
                            name={`${id}_length`}
                            aria-controls={id}
                            className="custom-select custom-select-sm form-control form-control-sm"
                            onChange={(val) => setPerPage(parseInt(val))}
                            value={perPage}
                            options={getPerPageOptions([10,25,50])}
                        />
                        
                    </div>
                </div>  

                <div className="p-1">

                    <Select
                        label="Jump to page"
                        hideLabel
                        name={`${id}_page-select`}
                        aria-controls={id}
                        className="form-control form-control-sm"
                        value={""} // after page is jumped to, reset to "Jump to Page"
                        onChange={(val) => goToPage(val)}
                        options={getPageSelectOptions(totalPages)}
                        disabled={totalPages === 1}
                    />
                    
                </div>
            </div>
        
        </div>


    )
}

/*Pagination.propTypes = {
    currentPage: PropTypes.number.isRequired, 
    goToPage: PropTypes.func,
    perPage: PropTypes.number.isRequired, 
    rowCount: PropTypes.number.isRequired, 
    setPerPage: PropTypes.func.isRequired,
}*/
