import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { ActionButton, Button } from "../../components/Buttons";
import { InnerPage } from "../../layout";
import { getPathFromRouteName } from "../../utils";
import api from '../../utils/api';
import { HOME, NEW_PROJECT, VIEW_PROJECT } from "../../utils/constants";
import { Alert, Icon } from "../../components/GeneralUI";
import { useAppState/*, useCancelAsyncCall*/ } from "../../hooks";


export const ListProjects = () => {

    //const cancelAsyncCall = useCancelAsyncCall();
    const [ projects, setProjects ] = useState(null);
    
    const navigate = useNavigate();
    
    const { setProject, errorCheckText } = useAppState();

    // Get the projects
    useEffect(() => {
        api.getProjects()
            .then(res => {
                //if (cancelAsyncCall()) return null;
                setProjects(res.data.projects)
            })
            .catch(err => {
                toast.error(errorCheckText(err, 'Unable to get Projects.'));
            })
    }, [ /*cancelAsyncCall, */errorCheckText ])


    // Switch projects:
    // Set in database, then redirect to Dashboard
    const changeProject = (project) => {
        api.setCurrentProject({ projectid: project.id })
            .then(res => {
                //if (cancelAsyncCall()) return null;
                setProject(res.data)
                navigate(getPathFromRouteName(HOME));
            })
            .catch(err => {
                toast.error(errorCheckText(err, `Unable to change current working project to id ${project.id}.`));    
            })  
    }

    return (
        <InnerPage
            title="Manage Projects"
            intro="Projects are a means of organizing your Variable Sets, Custom Variables, and Cohorts; they enable work for different studies (or other concerns) to be kept separate as desired. Use of Projects is optional (but encouraged)."
        >

            { (!projects || projects.length === 0) &&

                <Alert variant="info">
                    No projects have been created yet.
                </Alert>

            }

            <Button
                to={getPathFromRouteName(NEW_PROJECT)}
                size="md" 
                variant="secondary"
                classes="font-weight-600 mb-4"
                uppercase
                type="submit"
            >
                Add a Project <Icon name="plus-circle" />
            </Button>

            { projects && projects.length > 0 &&

                <table className="w-100">

                    <thead>

                        <tr>
                            <th>Name</th>
                            <th>Description</th>
                            <th className="text-center">Actions</th>
                        </tr>

                    </thead>

                    <tbody>

                        {projects && projects.map((project) => (
                            <tr key={project.id}>

                                <td>
                                    
                                    <Link to={getPathFromRouteName(VIEW_PROJECT, {id: project.id})}>
                                        {project.name} <Icon name="edit" />
                                    </Link>

                                </td>

                                <td>
                                    {project.description}
                                </td>

                                <td className="d-flex justify-content-center">

                                    <ActionButton
                                        action={{
                                            onClick: changeProject,
                                            variant: "secondary",
                                            iconName: "clipboard-check",
                                            tooltip: "Work on Project",
                                        }}                            
                                        entity={project}
                                    />

                                </td>

                            </tr>
                        ))}

                    </tbody>

                </table>
            }

        </InnerPage>
    )
}
