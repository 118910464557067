import { useState } from "react";
//import PropTypes from "prop-types";

import { ConditionalWrapper } from "../Utilities";
import { CheckboxInput } from "./CheckboxInput";

let count = 0;

export const CheckboxGroup = ({ hideLabel, legend, onChange, options, wrap }) => {

    // Generates automatic unique name for group
    const [ name ] = useState(('checkboxGroup-' + count++));

    const [ selectedOptions, setSelectedOptions ] = useState(new Set());

    const handleChange = v => {
        const newSelectedOptions = new Set(selectedOptions);

        if (selectedOptions.has(v)) {
            newSelectedOptions.delete(v);
        } else {
            newSelectedOptions.add(v);
        }
        
        setSelectedOptions(newSelectedOptions);

        onChange(newSelectedOptions)
    }

    return (
        <fieldset>

            <legend className={`"form-label ${hideLabel ? "sr-only" : ""}`}>
                {legend}
            </legend>

            
            <ConditionalWrapper
                condition={wrap && options.length > wrap}
                wrapper={children => (
                    <div className="row">
                        <div className="col-12 col-lg-8 checkbox-group--columns">
                            {children}
                        </div>
                    </div>
                )}
            >
                {
                    options.map((opt, i) => {
                        const value = opt.value ? opt.value : opt;
                        const label = opt.label ? opt.label : opt;
            
                        return (
                
                            <CheckboxInput
                                dense
                                key={i}
                                name={name}
                                onChange={handleChange}
                                checked={selectedOptions.has(value)}
                                value={value}
                                label={label}
                            />
                        )
                    })
                }
            </ConditionalWrapper>


        </fieldset>

    )
}

/*CheckboxGroup.propTypes = {
    hideLabel: PropTypes.bool,
    legend: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    options: PropTypes.arrayOf(
        PropTypes.oneOfType([
            PropTypes.shape({
                label: PropTypes.string,
                value: PropTypes.string,
            }),
            PropTypes.string
        ])
    ).isRequired,
    wrap: PropTypes.number,
}*/
