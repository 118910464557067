//import propTypes from "prop-types"
import { getPathFromRouteName/*, variableShape*/ } from "../../utils"
import { VIEW_VARIABLE } from "../../utils/constants"
import { IconButton } from "../Buttons"
import { DeleteConstructButton } from "../ClinicalConstructs"

/**
 * Default action buttons for a variable: un/favorite and details
 */
export const VariableDefaultActions = ({variable}) => {
    return (
        <>
            
            <IconButton
                to={getPathFromRouteName(VIEW_VARIABLE, { id: variable.variable_name})}
                iconName="table"
                tooltip="View Details"
                variant={"outline"}
                newTab
            />
            
            { variable.variable_type !== "variable" ? // construct
                    <DeleteConstructButton 
                        construct={variable}
                    />
              : <></>}
                
        </>
    )
}

/*VariableDefaultActions.propTypes = {
    variable: propTypes.shape(variableShape)
}*/
