import { ROUTES } from "./constants"

/**
 * 
 * @param {string} routeName; should be a constant from ROUTES
 * @param {object} params; i.e. {id: 1} if route has placeholder `id`, for example /cohorts/:id 
 * @returns string; represents the URL path
 */
export const getPathFromRouteName = (routeName, params) => {
    const route = ROUTES.find(r => r.name === routeName);
    let path = route.path;
    for (var key in params) {
        path = path.replace(`:${key}`, params[key])
    }

    return path;
}