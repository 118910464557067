/**
 * 
 * Helper to add classes, i.e. to a className string, if a condition is met.
 * Helps keep className="" clean 
 * 
 * @param {bool} condition 
 * @param {string} option1 
 * @param {string} option2 
 * @returns string
 */
export const condClass = (condition, option1, option2) => (condition ? ' ' + (option1 ? option1 : condition) : option2 ? ' ' + option2 : '')