import { useState } from "react";
//import PropTypes from "prop-types"

let count = 0;

export const RadioInput = ({ label, name, onChange, selectedOption, value, ...rest }) => {
    
    // Generates automatic unique id
    const [ id ] = useState(('radioInput-' + count++));

    const checked = selectedOption === value;

    const handleCheck = () => {
        if (!checked) {
            onChange(value)
        }
    }

    return (
        <div className="custom-control custom-radio">
            <input 
                type="radio" 
                className="custom-control-input" 
                name={name}
                id={id}
                value={value}
                checked={checked}
                onChange={handleCheck}
                {...rest}
            />

            <label 
                className="custom-control-label" 
                htmlFor={id}
            >
                {label}
            </label>
        </div>

    )
}


/*RadioInput.propTypes = {
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    selectedOption: PropTypes.string,
    value: PropTypes.string.isRequired,

}*/
