import { useEffect, useRef } from 'react';

/**
 * Stores the previous value of a variable, e.g. one that changes in state
 * so that it can be compared to the updated value
 **/ 
 export function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}
