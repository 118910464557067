import { CreateClinicalConstructPage } from '../../layout';
import { VARIABLE_TYPES } from '../../utils/constants';

export const NewMultiVarConstruct = () => {

    return (

        <CreateClinicalConstructPage
            title="Combine Multiple Variables"
            intro="This is an advanced form of creating a new Custom Variable by combining many variables at once."
            construct_type="multiple"
            alwaysHaveUnknown
            allowedTypes={[
                // all types allowed (except list?)
                VARIABLE_TYPES.CATEGORICAL, 
                VARIABLE_TYPES.DATE, 
                VARIABLE_TYPES.DICHOTOMOUS, 
                VARIABLE_TYPES.MULTICATEGORY, 
                VARIABLE_TYPES.NUMBERRANGE, 
                VARIABLE_TYPES.NULL,
                VARIABLE_TYPES.TEXT, 
            ]}
        />
    )    
}
