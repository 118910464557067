import { useState, useEffect } from "react";
import { toast } from "react-toastify"
//import PropTypes from "prop-types";

import { Button } from "../Buttons"
import { Icon, Spacer } from "../../components/GeneralUI"
import api from "../../utils/api"
import { useAppState } from "../../hooks"

const tableId = "download-preview"

/**
 * Table for displaying a preview of a download
 */
export const DownloadPreview = ({ cohortId, variableSetId, setDownloadIdParent }) => {
    
    const { errorCheckText } = useAppState();
    const [ downloadId, setDownloadId ] = useState(null);
    const [ records, setRecords ] = useState([]);
    const [ previewLoading, setPreviewLoading ] = useState(false);
    
    const updatePreview = () => {
        setPreviewLoading(true);
        api.createDataDownload({
            cohortid: cohortId,
            varsetid: variableSetId,
        })
        .then(res => {
            setDownloadId(res.data[0].download_id)
        })
        .catch(err => {
            toast.error(errorCheckText(err, 'Unable to generate Download for preview.'));
            setPreviewLoading(false)
        })
        // we let the downloadId useEffect handle actually getting the 
        // downloaded records and stuffing 'em in
    };
    
    useEffect(() => {
        if (downloadId) {
            api.previewDataDownload(downloadId)
            .then(res => {
                setRecords(res.data)
                setDownloadIdParent(downloadId)
            })
            .catch(err => {
                toast.error(errorCheckText(err, 'Unable to preview Download.'));
            })
            .finally(() => {
                setPreviewLoading(false)
            })
        } else {
            // no valid records; clean out existing
            setRecords([])
            if (setDownloadIdParent) { setDownloadIdParent(null) }
        }
    }, [ downloadId, errorCheckText, setRecords, setDownloadIdParent, setPreviewLoading ]);

    return (
            <section>
                <Spacer size={3} />
                <h3 className="h5">Preview of Records In Download</h3>
                <p>Only the first 10 individuals are listed.</p>
                <table className="w-100" id={tableId}>
    
                    {records?.length > 0 ? 
                        <>
                        <thead>
                            <tr>
                                {Object.keys(records[0]).map((key, i) => (
                                    <th key={i}>{key}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {records.map((record, i) => (
                                <tr key={i}>
                                    {Object.values(record).map((value, j) => (
                                        <td key={j}>{value}</td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                        </>
                    :
                        <>    
                        <thead>
                            <tr>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                            <td className="text-center">
                                <Button
                                    size="md"
                                    disabled={!cohortId || !variableSetId}
                                    variant="secondary"
                                    classes="font-weight-600 flex-grow-1"
                                    onClick={updatePreview}
                                    buttonLoading={previewLoading}
                                >
                                    <span className="btn--text">
                                        Preview <Icon name="check-circle" />
                                    </span>
                                </Button>
                            </td>
                            </tr>
                        </tbody>
                        </>
                    }
                </table>
            </section>
    )
}


/*DownloadPreview.propTypes = {
    cohortId: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.string,
        ]),
    variableSetId: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.string,
        ]),
    setDownloadIdParent: PropTypes.func,
}*/
