import { useCallback, useState } from 'react';
import { toast } from "react-toastify";

import { TextInput } from '../FormElements'
import api from '../../utils/api'
import { useAppState } from '../../hooks';
import { DeleteButton } from '../Buttons';

/**
 * Delete button + confirmation modal for deleting projects
 */
export const DeleteProjectButton = ({ project, returnRoute }) => {
    
    const { project: curproject, setProject: setCurProject, errorCheckText } = useAppState();

    const [ name, setName ] = useState("")

    const handleNameChange = useCallback((val) => setName(val), [])
    
    const deleteProject = (id) => {
        return api.deleteProject(id)
            .then(res => {
                if (id === curproject.project.id) {
                    // We just deleted the current project; need to force a
                    // switch to the default.
                    api.setCurrentProject({ projectid: 1 })
                        .then(res => {
                            setCurProject(res.data)
                        })
                        .catch(err => {
                            toast.error(errorCheckText(err, 'Unable to change current working project to default project.'))
                        })
                }
            })
            .catch(err => {
                toast.error(errorCheckText(err, 'Unable to delete Project.'))
            });
        }

    return (
        <DeleteButton
            id={project.id}
            entityLabel="Project"
            deleteFn={deleteProject}
            returnRoute={returnRoute}
            modal={{
                title: "Delete Project?",
                button: {
                    disabled: name !== project.name
                },
                body: (
                    <>
                        <p>Deleting this project will also delete all its Cohorts, Variable Sets, and Custom Variables.</p>
                        
                        <p>Please confirm that you’d like to delete <strong>{project.name}</strong> by typing the Project name <strong>{project.name}</strong> below.</p>

                        <TextInput
                            required
                            label="Project Name"
                            name="delete-confirm-name"
                            value={name}
                            onChange={handleNameChange}
                        />
                        
                        <p>Note that in some cases deletion can take several minutes. Please be patient.</p>
                    </>
                ),
            }}
        />
    )
}
