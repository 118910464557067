import { useCallback, useEffect, useState } from 'react';
//import PropTypes from 'prop-types';

import { Button, IconButton } from '../Buttons';
import { TextInput } from '../FormElements';
import { Icon } from '../GeneralUI'
import { ComplexValueDefinition } from '.';
import { useClinicalConstructContext } from '../../contexts';
import { updateItemInArray } from '../../utils';


export const ConstructValues = ({ handleAddValue, handleValueDescriptionChange, handleRemoveValue, handleValueNameChange, simple, alwaysHaveUnknown }) => {

    const { values, setConstraints : setContextConstraints } = useClinicalConstructContext();

    const [ constraints, setConstraints ] = useState([]);

    const [ hiddenValues, setHiddenValues ] = useState([false, false]);

    const setValueConstraints = useCallback((valueConstraints, index) => {
       
        setConstraints(prevConstraints => {
            const newConstraints = [...prevConstraints];
            newConstraints[index] = valueConstraints;
            return newConstraints
        })
    
    }, [])

    useEffect(() => {
        if (constraints && constraints.length) {
            setContextConstraints(constraints);
        }
    }, [constraints, setContextConstraints])

    const hide = (i) => {
        updateItemInArray(true, i, hiddenValues, setHiddenValues)
    }

    const show = (i) => {
        updateItemInArray(false, i, hiddenValues, setHiddenValues)
    }

    return (
        <section className="section--blue">

            { values.length > 0 ?
                values.map((value, i) => (
                    (alwaysHaveUnknown && value.name === "[UNK]") ||
                    <fieldset key={i} className="my-4 pb-4 value-definition">

                        <div className="d-flex align-items-center">
                            <legend className="text-uppercase font-weight-600 w-auto">Value {alwaysHaveUnknown ? i : i + 1}</legend>
                            
                            <IconButton
                                variant="info"
                                classes="mb-1"
                                onClick={hiddenValues[i] ? () => show(i) : () => hide(i)}
                                aria-label={`${hiddenValues[i] ? "Show" : "Hide" } Value Definition ${i}`}
                                tooltip={`${hiddenValues[i] ? "Show" : "Hide" } Value Definition`}
                                iconName={hiddenValues[i] ? "chevron-circle-down" : "chevron-circle-up"}
                            />

                        </div>
                                
                        <div className={hiddenValues[i] ? "d-none" : ""}>       
                            <div className="position-relative">

                                <TextInput
                                    name={`name-${i}`}
                                    value={value.name}
                                    onChange={(v) => handleValueNameChange(v, i)}
                                    fieldType="customvar_value"
                                    label="Name*"
                                />

                                <TextInput
                                    name={`description-${i}`}
                                    value={value.description}
                                    onChange={(v) => handleValueDescriptionChange(v, i)}
                                    label="Description"
                                    //textarea
                                />

                                <div style={{
                                    position: "absolute",
                                    right: 0,
                                    top: 10,
                                }}>
                                    <IconButton
                                        disabled={values.length <= 2}
                                        variant="info"
                                        onClick={() => handleRemoveValue(i)}
                                        aria-label={`Delete Value ${i}`}
                                        tooltip="Remove value"
                                        iconName="trash-alt"
                                        classes="mb-2"
                                    />
                                </div>

                            </div>
                
                            {!simple && 

                                <ComplexValueDefinition 
                                    index={i}
                                    setValueConstraints={setValueConstraints}
                                />
                            }
                        </div>
                    </fieldset>
                ))

                :

                    <div className="font-italic my-3">
                        No values defined yet.
                    </div>
            }


            <Button 
                size="md" 
                variant="secondary"
                classes="font-weight-600"
                uppercase
                onClick={handleAddValue}
            >
                Add a value <Icon name="plus-circle" />
            </Button>

        </section>
    )
}

/*ConstructValues.propTypes = {
    handleAddValue: PropTypes.func.isRequired,
    handleValueDescriptionChange: PropTypes.func.isRequired,
    handleRemoveValue: PropTypes.func.isRequired,
    handleValueNameChange: PropTypes.func.isRequired,
    // "simple" was marked as required, but as its sole use is actually
    // conditional, it clearly isn't required anymore
    simple: PropTypes.bool
}*/
