import { Link } from 'react-router-dom';
//import PropTypes from "prop-types";


/**
 * CTA card
 */
export const LinkedCard = ({title, to, text}) => {
    return (
        <Link to={to} className="linked-card">
            <div className="linked-card__title">{title}</div>
            <div className="linked-card__text">{text}</div>
            <div className="linked-card__cta">Get Started</div>
        </Link>
    )
}


/*LinkedCard.propTypes = {
    text: PropTypes.node.isRequired,
    title:PropTypes.node.isRequired,
    to: PropTypes.string.isRequired,
}*/
