import { useEffect } from "react";

// Helper to set the page title (and keeps it DRY by adding the suffix)
const setPageTitle = title => document.title = title + " | NRGR";

// Helper to set the page title on component mount
const usePageTitle = title => {
    
    useEffect(() => {
        setPageTitle(title)
    }, [title]);
    
}

export { usePageTitle, setPageTitle}
  
