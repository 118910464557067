import React, { useState } from 'react'
//import PropTypes from 'prop-types'

import { SubNavItem } from './SubNavItem'

// Main DIVER menu (sub menu in the context of the whole NIMH site)
export const SubNav = ({ menuItems }) => {

    const [ navExpanded, setNavExpanded ] = useState(false)

    return (
        <nav className="navbar navbar--sub-navigation navbar-expand-lg">

            <button
                className="navbar-toggler"
                type="button"
                aria-controls="diver-nav"
                aria-expanded={navExpanded}
                onClick={() => {setNavExpanded(!navExpanded)}}
            >
                Explore the DIVER Tool
            </button>

            <div className={`collapse navbar-collapse ${navExpanded ? "show" : ""}`} id="diver-nav">
                <ul className="navbar-nav justify-content-lg-around">

                    {menuItems.map((item, i) => (
                        <SubNavItem 
                            key={i} 
                            to={item.to}
                            childItems={item.childItems}
                            navExpanded={navExpanded}
                            collapseNav={() => setNavExpanded(false)}
                        >
                            {item.text}
                        </SubNavItem>
                    ))}
                    
                </ul>
            </div>
        </nav>
    )
}


/*SubNav.propTypes = {
    menuItems: PropTypes.arrayOf(PropTypes.shape({
        to: PropTypes.string,
        text: PropTypes.node.isRequired,
        childItems: PropTypes.arrayOf(PropTypes.shape({
            to: PropTypes.string,
            text: PropTypes.node.isRequired,
        })),
    }))
}*/
