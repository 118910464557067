//import PropTypes from 'prop-types';

import { Alert } from "../../components/GeneralUI";
import { DowncodeTableReadonly, ConstructMappingTableReadonly,  MultiLineConstraintReadonly } from "../../components/ClinicalConstructs";


export const ConstructConstructionDisplay = ({construct}) => {

    if (construct.variable_type === "variable") {
        return "N/A";
    } else if (construct.variable_type === "downcode") {
        return (
            <DowncodeTableReadonly construct={construct} />
        )
    } else if (construct.variable_type === "merge") {
        return (
            <ConstructMappingTableReadonly construct={construct} />
        )
    } else if (construct.variable_type === "multiple") {
        return construct.value_definitions.map( (val,i) => (
                <div key={val.name} className="my-3">

                    {/* Name */}
                    <div className="font-weight-600 mb-2">{val.name}</div> 
                    
                    {/* Description */}
                    <div className="mb-2">{val.description}</div>

                    {/* Definition */}
                    <div className="complex-value-definition">

                        { val.definition.constraints.map((row, j) => (

                            <div key={j} className="complex-value-definition__var mb-0">
                                <div className={`container px-2`}>
                                    {
                                        j !== 0 &&
                                            <div className="row mb-3">
                                                <div className={`align-items-center px-2`}> 
                                                
                                                    {val.definition.relationship}
                                                
                                                </div>    
                                            </div>
                                    }
                                    
                                    {/* 
                                    * Each of these is a "group" constraining one variable; 
                                    * They have the light blue background in the UI 
                                    */}
                                    <MultiLineConstraintReadonly
                                        index={i}
                                        gutterSize={2} 
                                        constraintGroup={row}
                                    />

                                </div>
                            </div>
                        ))}
                    </div>

                </div>
            ))
    } else {
        return (
            <Alert variant="warning">Unknown custom variable type "{construct.variable_type}"</Alert>
        )
    }
}

/*ConstructConstructionDisplay.propTypes = {
    construct: PropTypes.object,
}*/
