const addItemToArray = (item, arr, set) => {
    const newArr = [...arr];
    newArr.push(item);
    set(newArr)
    return newArr;
}

const removeItemFromArray = (index, arr, set) => {
    const newArr = [...arr];
    newArr.splice(index, 1);
    set(newArr)
    return newArr;
}

const updateItemInArray = (item, index, arr, set) => {
    const newArr = [...arr];
    
    if (newArr[index] !== item) {
        newArr[index] = item;
        set(newArr)
        return newArr;
    }
}

export { addItemToArray, removeItemFromArray, updateItemInArray }