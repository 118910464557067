import { useEffect, useState } from "react"
import { toast } from "react-toastify"

import { Select } from "../FormElements"
import api from '../../utils/api'
import { useAppState/*, useCancelAsyncCall*/ } from "../../hooks"

/**
 * Select input for choosing a cohort from "My Favorite Cohorts"
 * Abstracted out so call to API to get cohort to populate results can 
 * live here instead of in parents
 */
export const CohortSelection = (props) => {

    const [ cohorts, setCohorts ] = useState([])
    const { errorCheckText } = useAppState();
    
    //const cancelAsyncCall = useCancelAsyncCall();

    useEffect(() => {

        const getCohorts = async () => {
            try {
                const res = await api.getAllCohorts();
                
                //if (cancelAsyncCall()) return null

                setCohorts(res.data.cohorts)
            } catch (err) {
                toast.error(errorCheckText(err, 'Unable to get All Cohorts.'))
            }
        }

        getCohorts();
        
    }, [/*cancelAsyncCall, */errorCheckText])

    if (!cohorts || cohorts.length === 0) {
        return (
            <Select
                {...props}
                label="Cohort to show counts for"
                hideLabel
                disabled={true}
                name="relationship"
                hideArrow
                options={["No Cohorts Found"]}
            />
        )
    } 

    return (
        <Select
            {...props}
            label="Cohort to show counts for"
            hideLabel
            options={cohorts.map((cohort) => ({
                name: cohort.name,
                value: cohort.cohortid.toString(),
            }))}
        />
    )
}
