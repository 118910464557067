import { TextInput } from "."

/**
 * Wrapper for TextInput to validate for date
 * in specific format expected by BE
 */
export const ConstraintDateInput = (props) => {

    return (
        <TextInput
            fieldType="date_constraint"
            {...props}
        />
    )
}
