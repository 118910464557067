import { useEffect, useRef, useState } from "react";
//import PropTypes from "prop-types";

import { VariableTableRow } from "."
import { TableWrapper } from "../Tables";
import { condClass, /*variableShape, */variableFieldDisplay } from "../../utils";


// This *was* going to be just setting up a subset of variableFieldDisplay, but
// constants are UNDEFINED on initial import and will only be available within
// defined callback functions when called because something something
// Javascript. So it's handled in-component. Sigh.
const variableFieldNames = [
    'nameandcount',
    'fullquestion',
    'answerandtype',
    'topanswers',
    ]

export const VariablesTable = ({ id, variables, variableActions, isHidden/*, scopelimit*/ }) => {

    const tableRef = useRef();
    const rowRef = useRef();

    // gets the first row height to set it on the empty sticky header
    const [ rowHeight, setRowHeight ] = useState();
    const [ colWidths, setColWidths ] = useState([])
    const [ layoutFixed, setLayoutFixed ] = useState(false)
    const [ visibleVariables, setVisibleVariables] = useState([])
    
    const variableFields = variableFieldNames.map(
            field => variableFieldDisplay[field]);
    
    /*scopelimit = scopelimit ? scopelimit : 'all';*/


    useEffect(() => {
        setRowHeight(rowRef.current.clientHeight);
    }, [rowRef])

    /**
     * Helper to get the table to render with "natural" widths while also having the sticky actions column
     * 
     * 1. render with non-fixed layout
     * 2. then measure column widths
     * 3. set first columns accordingly
     * 4. then set the table to have sticky column
     */ 
    useEffect(() => {
        if (!layoutFixed) {
            
            const table = tableRef.current;
            const columns = table.querySelectorAll('th');

            const newColWidths = [];

            columns.forEach((col, i) => {
                let newWidth = variableFields[i] ? variableFields[i].width : 0;
                if (!newWidth) {
                    newWidth = col.offsetWidth // includes border
                } 
            
                newColWidths.push(newWidth);
            })

            setColWidths(newColWidths)
            setLayoutFixed(true)            
        } 
    }, [layoutFixed, tableRef, colWidths, variableFields/*, scopelimit*/])


    // Redraw table when the variables change, i.e. when paging or when there is a new search
    // Also redraw if our parent's "hidden" status has changed (because
    // otherwise the fixed layout will have been incorrect)
    useEffect(() => {
       if (visibleVariables) {
            setLayoutFixed(false)
            setColWidths([])
        }
    }, [visibleVariables, isHidden])


    const setStickyHeaderStyle = () => {
        const style = {}

        if (rowHeight) {
            style.height = rowHeight;
        } 
        
        if (colWidths[colWidths.length - 1]) {
            // This partly accounts for VariableDefaultActions. There's two
            // default actions for custom vars and one for regular vars.
            const defaultWidth = visibleVariables.some(v => v.variable_type !== 'variable') ? 120 : 75;
            style.width = variableActions && variableActions.length ? defaultWidth + (55 * variableActions.length) : defaultWidth;
        }

        return style;
    }

    return (

        <TableWrapper rows={variables} setVisibleRows={setVisibleVariables} tableId={id}>
                
            <div ref={tableRef} className={`table-scroll${condClass(visibleVariables.length, 'table-scroll--sticky')}`}>
                <div className="table-scroll__wrapper">
                    <table
                        id={id}
                        className="w-100"
                        style={visibleVariables.length && layoutFixed ? {tableLayout: 'fixed'} : null}
                    >
                        
                        {/* Table Header */}
                        <thead>
                            <tr ref={rowRef}>

                                {/* Header cells */}
                                {variableFields.map((col, i) =>
                                    <th key={i} style={visibleVariables.length ? {width: colWidths[i] } : null}>
                                        {col.fieldname}
                                    </th>
                                )}

                                {/* Actions */}
                                {
                                    visibleVariables.length ?

                                    // Invisible column -> holds space so table scrolls the whole way beneath the fixed cells
                                    <th aria-hidden style={{
                                        ...setStickyHeaderStyle(), 
                                        width: setStickyHeaderStyle().width ? setStickyHeaderStyle().width - 4 : "auto",
                                        color: "transparent", 
                                        borderLeftColor: "transparent"
                                    }}>
                                        Actions
                                    </th>

                                    : <></>
                                }
                                <th className="sticky-cell" style={setStickyHeaderStyle()}>Actions</th>

                            </tr>
                        </thead>

                        {/* Table Body */}
                        <tbody>

                            {
                                visibleVariables.length ?
                                    <>
                                        {
                                            visibleVariables.map((variable, i) => (
                                                <VariableTableRow
                                                    key={variable.variable_name}
                                                    variable={variable}
                                                    actions={variableActions}
                                                    colsDef={variableFields}
                                                   updateRowHeight={layoutFixed}
                                                    stickyCellWidth={setStickyHeaderStyle().width}
                                                />
                                            ))
                                        }
                                    </>
                                :
                                
                                <tr>
                                    <td colSpan="7" className="text-center">No results found.</td>                        
                                </tr>
                            
                            }
                        </tbody>
                    </table>
                </div>
            </div>

        </TableWrapper>
    )
}

/*VariablesTable.propTypes = {
    id: PropTypes.string.isRequired,
    variables: PropTypes.arrayOf(PropTypes.shape(variableShape)),
    variableActions: PropTypes.arrayOf(
        PropTypes.oneOfType([PropTypes.object,  PropTypes.func])
    ),
    isHidden: PropTypes.bool,
}*/
