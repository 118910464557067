import { useState, useCallback } from "react";
import { toast } from 'react-toastify';
//import PropTypes from "prop-types";
import api from "../../utils/api";
import { TextInput } from "../FormElements";
import { Button } from "../Buttons";
import { useAppState } from "../../hooks";
import { LoadingWrapper } from "./";

/**
 * Displays a login form if login has not yet been completed.
 */
export const LoginWrapper = ({ children }) => {
    
    const [ loading, setLoading ] = useState(false);
    const [ loginUsername, setLoginUsername ] = useState('');
    const [ loginPassword, setLoginPassword ] = useState('');
    
    const { userLoggedIn, checkForUserInfo, versionInfo, errorCheckText } = useAppState();
    
    const attemptLogin = useCallback(() => {
        const loginData = (loginUsername && loginPassword) ?
            { username: loginUsername, password: loginPassword } : undefined;
        setLoading(true);
        api.login(loginData)
        .then((res) => {
            checkForUserInfo();
        })
        .catch((err) => {
            // no error report if just doing verification test
            if (typeof loginData !== "undefined") {
                toast.error(errorCheckText(err, "Unable to log in."))
            }
        })
        .finally(() => setLoading(false));
    }, [loginUsername, loginPassword, setLoading, checkForUserInfo, errorCheckText])
    
    const handleSubmit = (event) => {
        event.preventDefault();
        attemptLogin();
    };
    
    return (
        <div>
            { userLoggedIn ? 
                children
            : 
                <LoadingWrapper loading={loading}>
                    <p>A login is required to use DIVER.</p>
                    
                    { window.diverwebUsingExternalAuthForm ?
                        <></>
                    :    
                        <form onSubmit={handleSubmit}>
                            <TextInput
                                name="username"
                                label="Username"
                                autoComplete="username"
                                required
                                value={loginUsername}
                                onChange={setLoginUsername}
                            />
                            <TextInput
                                name="password"
                                label="Password"
                                type="password"
                                autoComplete="current-password"
                                required
                                value={loginPassword}
                                onChange={setLoginPassword}
                            />
                            <Button type="submit">Log in</Button>
                        </form>
                    }
                </LoadingWrapper>
            }
            {/* Hacky way of displaying our version info. */}
            {/* FIXME: find something better and less obtrusive */}
            <p>CURRENT VERSIONS: diverweb {versionInfo.diverweb}, diverRPC {versionInfo.diverRPC}, db_procs {versionInfo.db_procs}, db_edit {versionInfo.db_edit}</p>
        </div>
    )
}

/*LoginWrapper.propTypes = {
    children: PropTypes.node.isRequired,
}*/
