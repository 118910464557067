//import PropTypes from "prop-types"

export const ParagraphLeadIn = ({children}) => {
    return (
        <p className="lead">{children}</p>
    )
}

/*ParagraphLeadIn.propTypes = {
    children: PropTypes.node
}*/
