import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

/**
 * Helper component that jumps the user back to the top 
 * of a page when a new route is triggered.
 * Mimics typical website navigational experience.
 */
export const ScrollToTop = () => {

    const pathname = useLocation();
    
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (null);
}
