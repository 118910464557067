import { useEffect, useRef, useState } from "react";
//import PropTypes from "prop-types";


/**
 * Large graded "V" in the UI for Cohort Logic page that demonstrates from which cohort
 * another cohort was derived
 */
export const ConnectingArrow = ({childCohortRef}) => {

    const [ arrowSource, setArrowSource ] = useState(0)
    const arrowRef = useRef();

    // Determine the location of the center of the cohort to which the arrow points
    useEffect(() => {

        if (childCohortRef && arrowRef && childCohortRef.current) {
            const childCohort = childCohortRef.current;
            const cohortRect = childCohort.getBoundingClientRect();
            const cohortWidth = cohortRect.right - cohortRect.left ;
            const cohortCenter = cohortRect.left + cohortWidth / 2;


            const arrow  = arrowRef.current;
            const arrowRect = arrow.getBoundingClientRect();
            const arrowLeft = arrowRect.left;
            const arrowRight = arrowRect.right;

            const relativeCenter =  (cohortCenter - arrowLeft) / (arrowRight - arrowLeft) * 100;

            setArrowSource(relativeCenter)
       }
    }, [arrowRef, childCohortRef])

    return (
        <div 
            ref={arrowRef} 
            style={{
                clipPath: `polygon(0 0, 100% 0, ${arrowSource}% 100%, ${arrowSource}% 100%)`
            }}            
            className="cohort__arrow" 
        />
    )
}


/*ConnectingArrow.propTypes = {
    childCohortRef: PropTypes.oneOfType([
        PropTypes.func, 
        PropTypes.shape({ current: PropTypes.any })
    ]).isRequired,
}*/
