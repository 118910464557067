//import PropTypes from "prop-types";

import { condClass } from '../../utils'

const parseIconWeight = (weight) => {
    switch (weight) {
        case 'solid':
        case 's':
        case 700:
        case '700':
            return 's';
        case 'regular':
        case 'r':
        case 400:
        case '400':
            return 'r';
        case 'light':
        case 'l':
        case 300:
        case '300':
            return 'r';
        default: 
            return 's';
            
    }
}

/** 
 * Font Awesome icon
 */
export const Icon = ({classes: additionalClasses, name, weight}) => {

    const classes = `fa${parseIconWeight(weight)} fa-${name}${condClass(additionalClasses, additionalClasses)}`;

    return (
        <span className={classes} aria-hidden="true"></span>
    )
}

/*Icon.propTypes = {
    classes: PropTypes.string,
    name: PropTypes.string,
    weight: PropTypes.oneOf([
        "solid",
        "s",
        700,
        "700",
        "regular",
        "r",
        400,
        "400",
        "light",
        "l",
        300,
        "300", 
    ]),
}*/
