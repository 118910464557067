import { useEffect, useState } from 'react';
//import PropTypes from 'prop-types';

import { Button } from '../Buttons';
import { TextInput } from '../FormElements';
import { Icon, Spacer } from '../GeneralUI'

export const ProjectForm = ({
    blueTheme = true,
    description: _description,
    name: _name,
    onReset,
    onSubmit,
    initialName,
    initialDescription,
    setName: _setName, 
    setDescription: _setDescriptionProp
}) => {

    const [name, setName] = useState(_name || initialName || '')
    const [description, setDescription] = useState(_description || initialDescription || '')
    
    // keep parent in sync if name comes from parent
    useEffect(() => {
        if (_name)
            setName(_name)
    }, [_name])

    // keep parent in sync if description comes from parent
    useEffect(() => {
        if (_description)
            setDescription(_description)
    }, [_description])

    // Set back to defaults on reset
    const handleReset = e => {
        e.preventDefault();

        setName(_name || '');
        setDescription(_description || '');

        if (onReset)
            onReset();        
    }

    // Prevent refresh and pass values to parent on submit
    const handleSubmit = e => {
        e.preventDefault();
        
        if (onSubmit){
            onSubmit({
                name,
                description
            });
        } 
    }

    // Set either parent or child "name" value depending on if value is controlled by parent
    const handleNameChange = (val) => {
        if (_setName) {
            _setName(val)
        }
        else {
            setName(val)
        }
    }
    
    // Set either parent or child "description" value depending on if value is controlled by parent
    const handleDescriptionChange = (val) => {
        if (_setDescriptionProp) {
            _setDescriptionProp(val)
        }
        else {
            setDescription(val)
        }
    }

    // Determine whether value comes from parent or child and return it
    const parentOrChildValue = (pVal, cVal) => {
        if (typeof pVal === 'undefined') {
            return cVal
        }
        return pVal;
    }
    
    
    return (
         
        <section className={blueTheme ? "section--blue" : null}>

            <form onSubmit={handleSubmit} onReset={handleReset}>
                
                <div>
                    
                    {/* Project Name */}
                    <TextInput 
                        name="project-title"
                        value={parentOrChildValue(_name, name)}
                        fieldType="project_name"
                        onChange={handleNameChange}
                        label="Project Name"
                    /> 

                    {/* Description */}
                    <TextInput
                        name="project-description"
                        value={parentOrChildValue(_description, description)}
                        onChange={handleDescriptionChange}
                        label="Description"
                        textarea
                    /> 

                </div>

                { (onReset || onSubmit) &&

                    <div className="d-flex mt-4 justify-content-end align-items-center">

                        { onReset &&
                            <Button
                                size="xs"
                                variant="primary"
                                classes="font-weight-600"
                                uppercase
                                type="reset"
                            >
                                Cancel
                            </Button>
                        }

                        <Spacer size={1} />

                        { onSubmit &&

                            <Button 
                                size="md" 
                                variant="secondary"
                                classes="font-weight-600"
                                uppercase
                                type="submit"
                            >
                                Save Details <Icon name="check-circle" />
                            </Button>
                        }

                    </div>
                }

            </form>

        </section>
    )
}

/*ProjectForm.propTypes = {
    blueTheme: PropTypes.bool,
    description: PropTypes.string,
    name: PropTypes.string,
    onReset: PropTypes.func,
    onSubmit: PropTypes.func,
    initialName: PropTypes.string,
    initialDescription: PropTypes.string,
    setName: PropTypes.func, 
    setDescription: PropTypes.func,
}*/
