//import PropTypes from "prop-types";
import { IconButton } from ".";

export const ActionButton = ({ action, entity, entityId }) => {
    
    let to = '';
    let onClick = null;

    // Replace any :id placeholder in a route with the id for this entity
    if (action.to) {
        to = action.to.replace(':id', entityId)
    } else if (action.onClick) {
        onClick = () => action.onClick(entity)
    }

    return (
        <IconButton
            {...action} 
            to={to} 
            onClick={onClick} 
            variant={action.variant ? action.variant : "outline"}
        />
    )
}


/*ActionButton.propTypes = {
    action: PropTypes.object,
    entity: PropTypes.object,
    entityId: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ])
}*/
