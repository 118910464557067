//import PropTypes from "prop-types";
import { LoadingSpinner } from ".";

/**
 * Displays the loading spinner if the loading condition is met
 */
export const LoadingWrapper = ({ children, loading, smallText }) => {
    return (
        <div>
            { loading ? 
                smallText ?
                    <>loading</>
                :
                <LoadingSpinner smallText={smallText} />
            : 
                children
            }
        </div>
    )
}

/*LoadingWrapper.propTypes = {
    children: PropTypes.node.isRequired,
    loading: PropTypes.bool,
    smallText: PropTypes.bool,
}*/
