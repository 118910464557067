import axios from "axios";

const baseUrl = '';
const apiPrefix = "/api/v0"
const apiUrl = baseUrl + apiPrefix;

const api = axios.create({
    withCredentials: process.env.NODE_ENV === 'development',
})

/**
 * @deprecated
 * This takes some data and returns it from a throttled Promise 
 * to simulate the async nature of an API call
 * Pass "rejectMe" equal to true to simulate a failed call
 */
/*const simulateApiCall = (returnVal, rejectMe) => (
    new Promise(function(resolve, reject) {
        setTimeout(function() {
            if (rejectMe) {
                reject();
            }
            resolve({data: returnVal})
        }, 500) // throttle to simulate loading data
    })
)*/


const getEndPoints = (api) => ({

    // Account
    login: data => api.post(`${apiUrl}/login`, data),
    logout: data => api.get(`${apiUrl}/logout`, data),
    getVersionInfo: () => api.get(`${apiUrl}/version`),
    getUserInfo: () => api.get(`${apiUrl}/userinfo`),

    // Cohorts
    createCohort: (data) => api.post(`${apiUrl}/cohort/new`, data),
    deleteCohort: id => api.delete(`${apiUrl}/cohort/delete?cohortid=${id}`),
    getAllCohorts: (projectid) => api.get(`${apiUrl}/cohort/info${projectid ? `?projectid=${projectid}` : ''}`),   // don't specify projectid unless absolutely necessary
    getAllCohortsForProject: (projectid) => api.get(`${apiUrl}/cohort/info`),
    getCohort: id => api.get(`${apiUrl}/cohort/info${id && `?cohortid=${id}` }`),
    getCohortSummary: (cohortId) => api.get(`${apiUrl}/cohort/summary?cohortid=${cohortId}`),
    previewCohort: data => api.post(`${apiUrl}/cohort/preview`, data),
    updateCohort: data => api.post(`${apiUrl}/cohort/edit`, data),

    // Constructs
    getCustomVariables: (projectid) => api.get(`${apiUrl}/construct/list${projectid ? `?projectid=${projectid}` : ''}`),   // don't specify projectid unless absolutely necessary
    createConstruct: data => api.post(`${apiUrl}/construct/new`, data),
    deleteConstruct: id => api.delete(`${apiUrl}/construct/delete?constructid=${id}`),
    getConstruct: id => api.get(`${apiUrl}/construct/info?constructid=${id}`),
    updateConstruct: data => api.post(`${apiUrl}/construct/edit`, data),
    copyConstruct: data => api.post(`${apiUrl}/construct/copy`, data),

    // Projects
    createProject: data => api.post(`${apiUrl}/project/new`, data),
    deleteProject: id => api.delete(`${apiUrl}/project/delete?projectid=${id}`),
    getProject: id => api.get(`${apiUrl}/project/info?projectid=${id}`),
    getProjects: () => api.get(`${apiUrl}/project/info`),
    updateProject: data => api.post(`${apiUrl}/project/edit`, data),
    getCurrentProject: () => api.get(`${apiUrl}/project/current`),
    setCurrentProject: data => api.post(`${apiUrl}/project/current`, data),

    // Variables
    getVariableByName: (names, cohortid) => api.post(`${apiUrl}/variable/lookup`, {
        variable_names: names,
        limit_cohortid: cohortid ? cohortid : null
    }),
    getVariablesBySearch: data => api.post(`${apiUrl}/variable/search`, data),
    editVariableFields: data => api.post(`${apiUrl}/variable/edit`, data),

    // Variable Sets
    createVariableSet: data => api.post(`${apiUrl}/variableset/new`, data),
    deleteVariableSet: id => api.delete(`${apiUrl}/variableset/delete?varsetid=${id}`),
    getAllVariableSets: (projectid) => api.get(`${apiUrl}/variableset/info${projectid ? `?projectid=${projectid}` : ''}`),   // don't specify projectid unless absolutely necessary
    getVariableSet: id => api.get(`${apiUrl}/variableset/info?varsetid=${id}`),
    updateVariableSet: data => api.post(`${apiUrl}/variableset/edit`, data),
    
    // Downloads
    createDataDownload: data => api.post(`${apiUrl}/cohort/download/new`, data),
    previewDataDownload: id => api.get(`${apiUrl}/cohort/download/preview?downloadid=${id}`),
    // The two below routes actually return URIs rather than request promises
    // because otherwise Axios won't send the file to the user client for
    // download.
    //getDataDownload: data => api.get(`${apiUrl}/cohort/download?downloadid=${data.downloadid}${data.filename ? `&filename=${data.filename}` : ''}`),
    //streamDataDownload: data => api.get(`${apiUrl}/cohort/downloadstream?downloadid=${data.downloadid}${data.filename ? `&filename=${data.filename}` : ''}`),
    getDataDownloadURI: data => `${apiUrl}/cohort/download?downloadid=${data.downloadid}&filename=${data.filename}`,
    // WARNING: stream endpoint not currently functional
    streamDataDownloadURI: data => `${apiUrl}/cohort/downloadstream?downloadid=${data.downloadid}&filename=${data.filename}`
})

export { apiUrl, getEndPoints }

export default getEndPoints(api);
