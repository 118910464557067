import { useEffect, useRef, useState } from 'react';
import { Link, useMatch } from 'react-router-dom'
//import PropTypes from 'prop-types';

import { condClass } from '../../utils/conditionalClass';

let counter = 0;

export const SubNavItem = ({ to, children, childItems, collapseNav, navExpanded, onClick }) => {
    counter++;

    let match = useMatch(to);

    const childrenMenuRef = useRef();
    const buttonRef = useRef();
    
    const [childrenMenuOpen, setChildrenMenuOpen ] = useState(false);

    const active = match && match.isExact;

    const handleClick = (e) => {
        if (e) e.preventDefault();
        setChildrenMenuOpen(!childrenMenuOpen);
    }

    // handle closing the children menu 
    const handleClickOutside = (event) => {
        // "click outside" doesn't count if this is the toggle button;
        // we handle that functionality separately.
        if (buttonRef.current && buttonRef.current.contains(event.target)) return;

        if (childrenMenuRef.current && !childrenMenuRef.current.contains(event.target)) {
            setChildrenMenuOpen(false)
        }
    }

    // set up/take down listeners for children menu
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);

        // cleanup
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        }
    }, [])

    const handleLinkClick = () => {
        if (onClick) onClick();
        collapseNav();
    }

    


    return (
        <li className={`${condClass(childItems,`has-children${navExpanded ? '' : ' d-flex'}`,)}${condClass(active,'active')}`}>
            
            { to || onClick ? 
                <Link to={to} onClick={handleLinkClick}>{children}</Link>
            :
                <>
                    <button
                        ref={buttonRef}
                        type="button"
                        className="sub-navigation__toggle non-link-item"
                        data-toggle="collapse"
                        data-target={`#subnav-${counter}`}
                        aria-expanded="false"
                        aria-controls={`subnav-${counter}`}
                        onClick={handleClick}
                    >
                        <span className="non-link-item__inner">{children}<span className="sr-only">Toggle Sub-Navigation</span></span>
                    </button>

                    <ul
                        ref={childrenMenuRef}
                        style={{
                            zIndex: 1020, // to go over sticky header cover
                            top: "100%"
                        }}
                        className={`collapse ${childrenMenuOpen ? "show" : ""}`}
                        id={`subnav-${counter}`}
                    >
                        {childItems.map((childItem, i) => (
                            <SubNavItem 
                                key={i} 
                                to={childItem.to}
                                onClick={handleClick}
                                collapseNav={collapseNav}
                            >
                                {childItem.text}
                            </SubNavItem>
                        ))}
                        
                    </ul>
                </>
            }   
        </li>
    )
}

/*SubNavItem.propTypes = {
    children: PropTypes.node.isRequired,
    childItems: PropTypes.arrayOf(PropTypes.shape({
        to: PropTypes.string,
        text: PropTypes.node.isRequired,
    })), 
    collapseNav: PropTypes.func, 
    navExpanded: PropTypes.bool,
    onClick: PropTypes.func,
    to: PropTypes.string,
}*/
